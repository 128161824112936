import {
  Alert,
  Grid,
  LinearProgress,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import Axios from "axios";
import React, { Component } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import Btn from "../../elements/Button";
import { selectCurrentUser } from "../../../redux/user/userSelector";

const styles = () => ({});
class AddSkill extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skillName: "",
      user: [],
      skillError: false,
      skillErrorMessage: "",
      loading: false,
      success: false,
    };
  }

  componentDidMount() {
    const { currentUser } = this.props;
    if (currentUser === null) {
      this.props.history.push({
        pathname: "/login",
      });
    } else {
      this.setState((prevState) => ({
        ...prevState,
        user: currentUser,
      }));
    }
  }

  clearForm = () => {
    this.setState((prevState) => ({
      ...prevState,
      skillName: "",
    }));
  };
  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      ...prevState,
      loading: true,
    }));
    // 'https://prod.paperflowapp.com/author-skills/add'

    await Axios.post(
      `${process.env.REACT_APP_API_URL}/skills/add`,
      {
        userId: this.state.user._id,
        skillName: this.state.skillName,
      },
      {
        headers: {
          Authorization: this.state.user.token,
        },
      }
    )
      .then(() => {
        this.clearForm();
        this.setState((prevState) => ({
          ...prevState,
          success: true,
          loading: false,
        }));
        setTimeout(() => {
          this.setState({ loading: false, success: false });
          this.props.history.push({
            pathname: `/home/skills`,
          });
        }, 3000);
      })
      .catch(() => {
        this.setState((prevState) => ({
          ...prevState,
          skillError: true,
          loading: false,
          skillErrorMessage: "Check Skill Name and try again!",
        }));
      });
  };
  render() {
    const validateForm = () => {
      return this.state.skillName.length > 0;
    };
    const handleSuccess = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }

      this.setState({ success: false });
    };

    const handleError = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }

      this.setState({ skillError: false });
    };

    return (
      <div className="skill" style={{ minWidth: "400px", maxWidth: "50%" }}>
        <BreadcrumbsItem to="/home/skills/addSkill">Add Skills</BreadcrumbsItem>
        <h1>Add New Skill</h1>
        <form className="skill__form" onSubmit={this.handleSubmit}>
          {this.state.loading ? <LinearProgress color="secondary" /> : <></>}
          <fieldset disabled={this.state.loading}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={3}
            >
              <Grid item>
                <TextField
                  style={{ minWidth: "300px", maxWidth: "50%" }}
                  margin="dense"
                  required
                  size="medium"
                  fullWidth
                  type="text"
                  id="skillName"
                  name="skillName"
                  value={this.state.skillName}
                  label="Skill Name"
                  onChange={(e) => this.setState({ skillName: e.target.value })}
                />
              </Grid>
              <Grid item xs sm={6}>
                <Btn
                  type="submit"
                  disabled={!validateForm()}
                  style={{ minWidth: "200px" }}
                >
                  Add Skill
                </Btn>
              </Grid>
            </Grid>
          </fieldset>
        </form>

        {this.state.success && (
          <Snackbar
            open={this.state.success}
            autoHideDuration={2000}
            onClose={handleSuccess}
          >
            <Alert onClose={handleSuccess} variant="filled" severity="success">
              <Typography variant="body2">Skill Added Successfully</Typography>
            </Alert>
          </Snackbar>
        )}
        {this.state.skillError && (
          <Snackbar
            open={this.state.skillError}
            autoHideDuration={2000}
            onClose={handleError}
          >
            <Alert onClose={handleError} variant="filled" severity="error">
              <Typography variant="body2">
                {this.state.skillErrorMessage}
              </Typography>
            </Alert>
          </Snackbar>
        )}
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});
export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(AddSkill)
);
