/* eslint-disable simple-import-sort/imports */
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import CustomLoader from '../elements/CustomLoader';

import { selectCurrentUser } from '../../redux/user/userSelector';

const PublicRoute = ({ currentUser, component: Component, ...restProps }) => {
  return (
    <Route
      {...restProps}
      component={(props) => {
        return (
          <>
            {currentUser ? (
              <Redirect to='/home' />
            ) : currentUser === null ? (
              <Component {...props} />
            ) : (
              <CustomLoader />
            )}
          </>
        );
      }}
    />
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(PublicRoute);
