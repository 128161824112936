/* eslint-disable unused-imports/no-unused-vars */
import { Alert, Button, Snackbar } from '@mui/material';
import { withStyles } from '@mui/styles';
import Axios from 'axios';
import React, { Component } from 'react';

import CustomLoader from '../../elements/CustomLoader';

const styles = () => ({
  button: {
    fontSize: 14,
    textTransform: 'none',
    '&:disabled': {
      backgroundColor: 'grey',
    },
  },
});
class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mobile: '',
      loading: false,
      onError: false,
    };
  }

  signup = (e) => {
    e.preventDefault();
    this.props.history.push({
      pathname: '/signup',
    });
  };

  render() {
    const handleSubmit = async (e) => {
      e.preventDefault();
      const mobile2 = '+91';
      const phone = mobile2.concat(this.state.mobile);
      this.setState({ loading: true });

      // http://localhost:4000/author/getOtp
      Axios.post('https://prod.paperflowapp.com/author-auth/author/getOtp', {
        mobile: phone,
        type: 'LOGIN',
      })
        .then((response) => {
          this.setState({ loading: false });
          this.props.history.push({
            pathname: '/verifyOtp',
            data: {
              mobile: phone,
              type: 'LOGIN',
            },
          });
        })
        .catch((error) => {
          this.setState({ loading: false, onError: true });
        });
    };

    const validateForm = () => {
      return this.state.mobile.length > 0;
    };
    const handleError = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }

      this.setState({ onError: false });
    };

    const { classes } = this.props;

    return (
      <div className='login'>
        <div className='login-container'>
          <h2>Login with OTP</h2>
          <form aria-busy={this.state.loading}>
            <div className='user-box'>
              <span className='country-code'>{`+91`}</span>
              <input
                type='number'
                id='mobile'
                value={this.state.mobile}
                name='mobile'
                onChange={(e) => this.setState({ mobile: e.target.value })}
                required
                autoComplete='true'
              />
              <label>Mobile Number</label>
              <span className='country-code'>{`+91`}</span>
            </div>
            <Button
              disabled={!validateForm()}
              variant='contained'
              color='default'
              className={classes.button}
              onClick={handleSubmit}>
              Submit
            </Button>
          </form>
          {/* <Button style={{ marginTop: '16px' }} href="#text-buttons" onClick={e => this.signup(e)} color="primary">
                        Create a new account!
                    </Button> */}
        </div>

        {this.state.loading && (
          <div className='loading-overlay'>
            <CustomLoader />
          </div>
        )}
        {this.state.error && (
          <div>
            <Snackbar
              open={this.state.error}
              autoHideDuration={3000}
              onClose={handleError}>
              <Alert
                variant='filled'
                elevation={6}
                onClose={handleError}
                severity='error'>
                Incorrect Mobile Number! Please Try again!
              </Alert>
            </Snackbar>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Login);
