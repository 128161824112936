/* eslint-disable unused-imports/no-unused-vars */
import { Add, Delete, Visibility } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Button,
  IconButton,
  Snackbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { red } from '@mui/material/colors';
import { withStyles } from '@mui/styles';
import Axios from 'axios';
import MaterialTable from 'material-table';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectCurrentBook } from '../../../redux/book/bookSelector';
import { selectCurrentUser } from '../../../redux/user/userSelector';

const styles = (theme) => ({
  root: {
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '0px',
    padding: '0px',
    width: '300',
    outline: 'none',
    fontSize: '0px',
    lineHeight: '0px',
    maxWidth: 335,
    // fontSize: 40,
    // height: 'auto'
  },
  media: {
    width: '100%',
    // height: 300,
    borderRadius: '4px',
    position: 'relative',
    zIndex: 1000,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  addBook: {
    width: 'auto',
    maxWidth: 325,
    height: 'auto',
    textAlign: 'center',
  },
  cardImage: {
    margin: '0px',
    padding: '0px',
    width: '100%',
    outline: 'none',
    // height:'auto'
  },
  cardHeader: {
    fontSize: '12px',
  },
  button: {
    background: '#005792',
    borderRadius: '2px',
    color: '#FBFAF2',
    fontFamily: 'Rubik',
    fontWeight: '500',
    textAlign: 'center',
    fontSize: 14,
    textTransform: 'none',
    '&:hover': {
      // background: '#B5CCEC',
      background: '#01204A',
    },
  },
  alert: {
    // width: "250px",
  },
});

class Tests extends Component {
  constructor(props) {
    super(props);

    this.state = {
      book: [],
      author: [],
      columns: [
        {
          title: 'ASSESSMENT NAME',
          field: 'testName',
          editable: 'onUpdate',
          cellStyle: {
            textAlign: 'left',
            fontWeight: '500',
            fontSize: 16,
            color: '#01204A',
            borderColor: '#E6EEF8',
          },
        },
        {
          title: 'TOTAL QUESTIONS',
          field: 'totalQuetions',
          editable: 'never',
          width: 'auto',
        },
        {
          title: 'ACTION',
          editable: 'never',
          width: 'auto',
          cellStyle: {
            textAlign: 'center',
          },
          render: (rowData) => (
            <>
              {/* <Tooltip title="Edit Test">
                <IconButton
                  aria-label="edit test"
                  style={{
                    background: "none",
                    color: "#A4A9AF",
                    marginRight: "6px",
                  }}
                  size="small"
                  // disabled={rowData.status === 'Processing'}
                  onClick={(e) => {
                  }}
                >
                  <EditIcon style={{ fontSize: "22px" }} />
                </IconButton>
              </Tooltip> */}
              {/* <IconButton
                                aria-label="Delete Book"
                                // disabled={rowData.status === 'Processing'}
                                style={{ background: "none", color: "#A4A9AF" }}
                                size="small"
                                onClick={(e) => { this.deleteBook(rowData) }}
                            >
                                <DeleteIcon style={{ fontSize: '16px' }} />
                            </IconButton> */}
              <Tooltip title='View Assessment'>
                <IconButton
                  aria-label='View Assessment'
                  // disabled={rowData.status === 'Processing'}
                  style={{
                    background: 'none',
                    color: '#A4A9AF',
                    marginRight: '6px',
                  }}
                  size='small'
                  onClick={(e) => {
                    this.viewTest(rowData);
                  }}
                >
                  {/* <Edit style={{ fontSize: "22px" }} /> */}
                  <Visibility style={{ fontSize: '22px' }} />
                </IconButton>
              </Tooltip>
              <Tooltip title='Delete Assessment'>
                <IconButton
                  aria-label='Delete Assessment'
                  // disabled={rowData.status === 'Processing'}
                  style={{
                    background: 'none',
                    color: '#A4A9AF',
                    marginRight: '6px',
                  }}
                  size='small'
                  onClick={() => {
                    this.deleteTest(rowData);
                  }}
                >
                  <Delete style={{ fontSize: '22px' }} />
                </IconButton>
              </Tooltip>
            </>
          ),
        },
      ],
      data: [],

      options: {
        paging: true,
        search: true,
        selection: false,
        searchFieldAlignment: 'left',
        searchFieldStyle: {
          backgroundColor: '#FFFFFF',
          // border: '1px solid #B5CCEC',
          borderRadius: '2px',
          fontFamily: 'Rubik',
          fontWight: '400',
          fontSize: '14px',
          color: '#A4A9AF',
          letterSpacing: '0px',
        },
        actionsColumnIndex: -1,

        headerStyle: {
          textAlign: 'center',
          fontWeight: '500',
          fontSize: 12,
          color: '#707A85',
          borderTop: '1px solid',
          borderColor: '#E6EEF8',
          // paddingLeft: 0,
          // marginLeft: 0
        },
        cellStyle: {
          textAlign: 'center',
          fontWeight: '400',
          fontSize: 16,
          color: '#01204A',
          borderColor: '#E6EEF8',
        },
        actionsCellStyle: {
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        },
        rowStyle: {
          textAlign: 'center',
          padding: 'auto',
        },
      },
      testError: false,
      testErrorTitle: '',
      testSuccess: false,
      testSuccessTitle: '',
      testSuccessMessage: '',
      emptyDataSourceMessage: 'Loading assessments. . .',
    };
  }

  componentDidMount() {
    const token = JSON.parse(localStorage.getItem('token'));
    if (this.props.location.data === undefined) {
      this.props.history.push({
        pathname: `/home/books`,
      });
    } else {
      document.title = 'Smart Paper CMS | Assessments';
      const { book, author } = this.props.location.data;
      this.setState({ book: book, author: author }, () => this.getTests(token));
    }
  }

  addNewTest = (e) => {
    const { pathname } = this.props.location;
    this.props.history.push({
      pathname: `${pathname}/addTest`,
      data: {
        book: this.state.book,
        type: 'create',
      },
    });
  };
  // 'https://prod.paperflowapp.com/author-test/listTest'

  getTests = async (token) => {
    await Axios.post(
      `${process.env.REACT_APP_API_URL}/test/get`,
      {
        bookId: this.state.book._id,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((response) => {
        const { tests } = response.data;
        if (tests.length > 0) {
          this.setState({ data: tests });
        }
        this.setState({ emptyDataSourceMessage: 'No assessments found.' });
      })
      .catch((error) => {});
  };

  viewTest = (test) => {
    const { pathname } = this.props.location;
    this.props.history.push({
      pathname: `${pathname}/viewTest`,
      data: {
        test: test,
        book: this.state.book,
        type: 'view',
      },
    });
  };

  // 'https://prod.paperflowapp.com/author-test/delete'
  deleteTest = async (test) => {
    const testName = test.testName;
    const token = JSON.parse(localStorage.getItem('token'));
    const enteredName = window.prompt(`Please type ${testName} to confirm.`);
    if (enteredName === testName) {
      await Axios.post(
        `${process.env.REACT_APP_API_URL}/test/remove`,
        {
          testId: test._id,
          testName: test.testName,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
        .then((response) => {
          this.setState({
            testSuccess: true,
            testSuccessTitle: 'Assessment Delete Success',
            testSuccessMessage: `Assessment: ${testName} deleted successfully.`,
          });
          this.getTests(token);
        })
        .catch((error) => {
          this.setState({
            testError: true,
            testErrorTitle: 'Assessment Delete Error',
            testErrorMessage: error.response.data.message,
          });
        });
    } else {
      this.setState({
        testError: true,
        testErrorTitle: 'Assessment Delete Error',
        testErrorMessage: 'Incorrect assessment name entered.',
      });
    }
  };

  handleError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ testError: false, testErrorTitle: '' });
  };
  handleSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ testSuccess: false, testSuccessTitle: '' });
  };

  render() {
    const { classes } = this.props;

    return (
      <>
        <MaterialTable
          style={{
            minWidth: '500px',
            width: '100%',
            maxWidth: '100%',
            textAlign: 'center',
          }}
          localization={{
            body: {
              emptyDataSourceMessage: `${this.state.emptyDataSourceMessage}`,
              editRow: { deleteText: 'Are you sure you want to delete?' },
              fontSize: '14px',
            },
            toolbar: { searchPlaceholder: 'Search Assessments' },
          }}
          options={this.state.options}
          title=''
          columns={this.state.columns}
          data={this.state.data}
          actions={this.state.actions}
          components={{
            Actions: (props) => (
              <Button
                onClick={(event) => this.addNewTest(event)}
                variant='contained'
                color='secondary'
                className={classes.button}
                startIcon={<Add />}
                size='small'
                style={{ marginRight: '50px' }}
              >
                Add Assessment
              </Button>
            ),
          }}
        />

        {this.state.testError && (
          <Snackbar
            open={this.state.testError}
            autoHideDuration={4500}
            onClose={this.handleError}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Alert
              variant='filled'
              elevation={6}
              onClose={this.handleError}
              severity='error'
              className={classes.alert}
            >
              <AlertTitle>{this.state.testErrorTitle}</AlertTitle>
              <Typography variant='body2'>
                {this.state.testErrorMessage}
              </Typography>
            </Alert>
          </Snackbar>
        )}
        {this.state.testSuccess && (
          <Snackbar
            open={this.state.testSuccess}
            autoHideDuration={4500}
            onClose={this.handleSuccess}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Alert
              variant='filled'
              elevation={6}
              onClose={this.handleSuccess}
              severity='success'
              className={classes.alert}
            >
              <AlertTitle>{this.state.testSuccessTitle}</AlertTitle>
              <Typography variant='body2'>
                {this.state.testSuccessMessage}
              </Typography>
            </Alert>
          </Snackbar>
        )}

        {/* Existing Books */}
        {/* {
                        this.state.data.map((book, index) => (
                            <Grid item key={index} >
                                <Card className={classes.root}>
                                    <CardActions>
                                        <Typography variant="subtitle2" style={{ color: '#163172' }}> Status : {book.status} </Typography>
                                        <Button size="small" onClick={e => editBook(book)} style={{ marginLeft: 'auto' }} color="primary">
                                            Edit Book
                                        </Button>
                                    </CardActions>

                                    <CardActionArea className='bookList'>
                                        <CardHeader
                                            title={book.bookName}
                                            titleTypographyProps={{ variant: 'subtitle1' }}
                                        />
                                        <img src={book.thumbnailUrl} alt={book.bookName} className={classes.cardImage} />
                                    </CardActionArea>
                                </Card>

                            </Grid>
                        ))} */}
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  currentBook: selectCurrentBook,
});

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(Tests)
);
