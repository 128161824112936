import { Add, ArrowForwardIos, Delete, Edit } from '@mui/icons-material';
import {
  Alert,
  Button,
  Grid,
  IconButton,
  Snackbar,
  Typography,
} from '@mui/material';
import { red } from '@mui/material/colors';
import { withStyles } from '@mui/styles';
import Axios from 'axios';
import MaterialTable from 'material-table';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectCurrentUser } from '../../../redux/user/userSelector';

const styles = (theme) => ({
  root: {
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '0px',
    padding: '0px',
    width: '300',
    outline: 'none',
    fontSize: '0px',
    lineHeight: '0px',
    maxWidth: 335,
    // fontSize: 40,
    // height: 'auto'
  },
  media: {
    width: '100%',
    // height: 300,
    borderRadius: '4px',
    position: 'relative',
    zIndex: 1000,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  addBook: {
    width: 'auto',
    maxWidth: 325,
    height: 'auto',
    textAlign: 'center',
  },
  cardImage: {
    margin: '0px',
    padding: '0px',
    width: '100%',
    outline: 'none',
    // height:'auto'
  },
  cardHeader: {
    fontSize: '12px',
  },
  button: {
    background: '#005792',
    borderRadius: '2px',
    color: '#FBFAF2',
    fontFamily: 'Rubik',
    fontWeight: '500',
    textAlign: 'center',
    fontSize: 14,
    textTransform: 'none',
    '&:hover': {
      // background: '#B5CCEC',
      background: '#01204A',
    },
  },
});

const token = JSON.parse(localStorage.getItem('token'));
class RecoTests extends Component {
  constructor(props) {
    super(props);

    this.state = {
      book: [],
      author: [],
      columns: [
        {
          title: 'TEST NAME',
          field: 'recommendationName',
          editable: 'onUpdate',
          cellStyle: {
            textAlign: 'left',
            fontWeight: '500',
            fontSize: 16,
            color: '#01204A',
            borderColor: '#E6EEF8',
          },
        },
        {
          title: 'RECO CODE',
          field: 'recommendationCode',
          editable: 'never',
          width: 'auto',
          render: (rowData) => (
            <Typography variant='subtitle2'>
              {rowData.recommendationCode.toString()}
            </Typography>
          ),
        },
        {
          title: 'TOTAL QUEs',
          field: 'totalQuestions',
          editable: 'never',
          width: 'auto',
        },
        {
          title: 'Split Points',
          field: 'splitPoints',
          editable: 'never',
          width: 'auto',
          render: (rowData) => (
            <Typography variant='subtitle2'>
              {rowData.splitPoints.toString()}
            </Typography>
          ),
        },
        {
          title: 'ACTION',
          editable: 'never',
          width: 'auto',
          cellStyle: {
            textAlign: 'center',
          },
          render: (rowData) => (
            <>
              <IconButton
                aria-label='edit book'
                style={{ background: 'none', color: '#A4A9AF' }}
                size='small'
                // disabled={rowData.status === 'Processing'}
                onClick={() => {
                  this.editBook(rowData);
                }}>
                <Edit style={{ fontSize: '16px' }} />
              </IconButton>
              <IconButton
                aria-label='Delete Book'
                // disabled={rowData.status === 'Processing'}
                style={{ background: 'none', color: '#A4A9AF' }}
                size='small'
                onClick={() => {
                  this.deleteBook(rowData);
                }}>
                <Delete style={{ fontSize: '16px' }} />
              </IconButton>
              <IconButton
                aria-label='View Book'
                // disabled={rowData.status === 'Processing'}
                style={{ background: 'none', color: '#A4A9AF' }}
                size='small'
                onClick={() => {
                  this.viewTest(rowData);
                }}>
                <ArrowForwardIos style={{ fontSize: '16px' }} />
              </IconButton>
            </>
          ),
        },
      ],
      data: [],

      options: {
        paging: true,
        search: true,
        selection: false,
        searchFieldAlignment: 'left',
        searchFieldStyle: {
          backgroundColor: '#FFFFFF',
          // border: '1px solid #B5CCEC',
          borderRadius: '2px',
          fontFamily: 'Rubik',
          fontWight: '400',
          fontSize: '14px',
          color: '#A4A9AF',
          letterSpacing: '0px',
        },
        actionsColumnIndex: -1,

        headerStyle: {
          textAlign: 'center',
          fontWeight: '500',
          fontSize: 12,
          color: '#707A85',
          borderTop: '1px solid',
          borderColor: '#E6EEF8',
          // paddingLeft: 0,
          // marginLeft: 0
        },
        cellStyle: {
          textAlign: 'center',
          fontWeight: '400',
          fontSize: 16,
          color: '#01204A',
          borderColor: '#E6EEF8',
        },
        actionsCellStyle: {
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        },
        rowStyle: {
          textAlign: 'center',
          padding: 'auto',
        },
      },
      testError: false,
    };
  }

  componentDidMount() {
    if (this.props.location.data === undefined) {
      this.props.history.push({
        pathname: `/home/books`,
      });
    } else {
      document.title = 'Authoring Tool | Reco Tests';
      const { book, author } = this.props.location.data;
      this.setState({ book: book, author: author }, () => this.getTests());
    }
  }

  addNewTest = () => {
    const { pathname } = this.props.location;
    this.props.history.push({
      pathname: `${pathname}/addRecoTest`,
      data: this.state.author,
    });
  };

  getTests = () => {
    Axios.post(
      'https://prod.paperflowapp.com/author-reco/getReco',
      {
        bookId: this.state.book._id,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((response) => {
        this.setState({ data: response.data.recoTest });
      })
      .catch(() => {});
  };

  viewTest = (test) => {
    const { pathname } = this.props.location;
    this.props.history.push({
      pathname: `${pathname}/viewTest`,
      data: {
        test: test,
        book: this.state.book,
        type: 'view',
      },
    });
  };

  render() {
    const { classes } = this.props;

    const handleError = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }

      this.setState({ testError: false });
    };
    return (
      <div>
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='center'
          spacing={3}>
          <Grid item xs={10}>
            <MaterialTable
              style={{
                minWidth: '800px',
                maxWidth: '100%',
                textAlign: 'center',
              }}
              localization={{
                body: {
                  emptyDataSourceMessage: 'No Test is created yet!',
                  editRow: { deleteText: 'Are you sure you want to delete?' },
                  fontSize: '14px',
                },
                toolbar: { searchPlaceholder: 'Search Tests' },
              }}
              options={this.state.options}
              title='Adaptive Tests'
              columns={this.state.columns}
              data={this.state.data}
              actions={this.state.actions}
              components={{
                Actions: () => (
                  <Button
                    onClick={(event) => this.addNewTest(event)}
                    variant='contained'
                    color='secondary'
                    className={classes.button}
                    startIcon={<Add />}
                    size='small'
                    style={{ marginRight: '50px' }}>
                    Add Test
                  </Button>
                ),
              }}
            />
          </Grid>

          {this.state.testError && (
            <Snackbar
              open={this.state.testError}
              autoHideDuration={4000}
              onClose={handleError}>
              <Alert
                variant='filled'
                elevation={6}
                onClose={handleError}
                severity='error'>
                <Typography className='bookDeleteError'>
                  Could not delete book, Try again!
                </Typography>
              </Alert>
            </Snackbar>
          )}

          {/* Existing Books */}
          {/* {
                        this.state.data.map((book, index) => (
                            <Grid item key={index} >
                                <Card className={classes.root}>
                                    <CardActions>
                                        <Typography variant="subtitle2" style={{ color: '#163172' }}> Status : {book.status} </Typography>
                                        <Button size="small" onClick={e => editBook(book)} style={{ marginLeft: 'auto' }} color="primary">
                                            Edit Book
                                        </Button>
                                    </CardActions>

                                    <CardActionArea className='bookList'>
                                        <CardHeader
                                            title={book.bookName}
                                            titleTypographyProps={{ variant: 'subtitle1' }}
                                        />
                                        <img src={book.thumbnailUrl} alt={book.bookName} className={classes.cardImage} />
                                    </CardActionArea>
                                </Card>

                            </Grid>
                        ))} */}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(RecoTests)
);
