import React from 'react';

const Explorer = () => {
  return (
    <div style={{ margin: '0px', padding: '0px', overflow: 'hidden' }}>
      <iframe
        title='Smart Paper API'
        src='https://devhpc.smartpaperapp.com/docs'
        frameBorder='0'
        style={{ overflow: 'hidden', height: '100vh', width: '100%' }}
        height='100vh'
        width='100%'
      />
    </div>
  );
};

export default Explorer;
