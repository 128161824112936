/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { DeleteOutline, ExpandMore, InfoRounded } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  Snackbar,
  Tab,
  TextField,
  Tooltip,
  tooltipClasses,
  Typography,
} from '@mui/material';
import { styled, withStyles } from '@mui/styles';
import Axios from 'axios';
// eslint-disable-next-line unused-imports/no-unused-imports
import Ace from 'brace';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
// import MultiCrops from 'react-multi-crop-tool';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import 'brace/mode/json';
import 'brace/theme/github';

import JSONEditorReact from './JSONEditorReact';
import { uuid } from '../Konva/CustomUuid';
// import { KonvaCropper } from '../Konva/KonvaCropper';
import { KonvaCropper } from '../Konva/KonvaCropper';
import { CustomDrawer } from '../../elements/CustomDrawer';
import { selectCurrentBook } from '../../../redux/book/bookSelector';
import { selectCurrentUser } from '../../../redux/user/userSelector';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#000000',
    color: 'rgba(255, 255, 255, 0.87)',
    // maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const CustomAccordion = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '8px',
  '&:before': {
    display: 'none',
  },
}));

const CustomAccordionSummary = styled((props) => (
  <AccordionSummary
    {...props}
    // expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} onClick={console.log('first',props)}/>}
    sx={{
      padding: '0 4px 0 8px',
      backgroundColor: '#dedcdc',
      borderRadius: '8px',
      marginLeft: 0,
    }}
  />
))(({ theme }) => ({
  borderRadius: '8px',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(270deg)',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(360deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  // border: '1px solid black',
  borderRadius: '8px',
  // borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const styles = (theme) => ({
  input: {
    display: 'none',
  },
  uploadButton: {
    background: '#005792',
    borderRadius: '2px',
    color: '#FBFAF2',
    fontFamily: 'Rubik',
    fontWeight: '400',
    textAlign: 'center',
    fontSize: 14,
    margin: '6px auto',
    textTransform: 'none',
    cursor: 'pointer',
    // margin: theme.spacing(1),
  },
  deleteButton: {
    background: '#920000',
    borderRadius: '2px',
    color: '#FBFAF2',
    fontFamily: 'Rubik',
    fontWeight: '400',
    textAlign: 'center',
    fontSize: 14,
    margin: '6px auto',
    textTransform: 'none',
    cursor: 'pointer',
    // margin: theme.spacing(1),
  },
  answerBox: {
    fontWeight: '400',
    fontSize: 20,
    textTransform: 'none',
    marginTop: '16px',
  },
  formControl: {
    fontWeight: '400',
    fontSize: 20,
    textTransform: 'none',
    marginTop: '16px',
  },
  progress: {
    maxWidth: '100px',
    width: '80px',
    height: '80px',
  },
  qbox: {
    fontWeight: '400',
    fontSize: '20px',
    marginTop: '16px',
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
  },
  inputField: {
    position: 'fixed',
    right: '10px',
  },
  root: {
    whiteSpace: 'unset',
    wordBreak: 'break-all',
  },
});

const EditAruco = (props) => {
  const modes = ['tree', 'code', 'text'];
  const token = JSON.parse(localStorage.getItem('token'));

  const [state, setState] = useState({
    file: null,
    src: null,
    crop: {
      unit: 'px',
      x: 0,
      y: 0,
      width: 100,
    },
    answer: '',
    metaData: [],
    jsonData: [],
    mode: 'text',
    text: '',
    question: 1,
    coordinates: [],
    pageId: 0,
    pageNumber: 0,
    type: 'practice',
    questions: 1,
    pageType: 'subj_nonuniform_practice',
    dewarp: 1.0,
    gridHeight: 1280,
    height: 1280,
    gridWidth: 960,
    book: [],
    author: [],
    skills: [],
    width: 960,
    skill: '',
    frontendId: '',
    isPictureAdded: false,
    contentSubType: '',
    contentType: 'question',
    maxScore: 1,
    bookType: '',
    tabValue: '1',
    questionLabel: '',
    accordionState: false,
    deleteSnackbarStatus: false,
    editState: false,
    tempMetaData: null,
    // draw: true,
  });
  // let imageRef = createRef();
  // let image2Ref = createRef();

  useEffect(() => {
    const handleTabClose = (event) => {
      event.preventDefault();
      console.log('beforeunload event triggered');
      return (event.returnValue = 'Are you sure you want to exit?');
    };
    window.addEventListener('beforeunload', handleTabClose);
    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, []);

  useEffect(() => {
    if (props.location.data === undefined) {
      props.history.push({
        pathname: `/home/books/${props.currentBook._id}/resources`,
      });
    } else {
      document.title = 'Authoring Tool | Page Details';
      const { book, author, pageDetails } = props.location.data;
      const token = JSON.parse(localStorage.getItem('token'));
      setState((prevState) => ({
        ...prevState,
        book: book,
        bookType: book.bookType,
        author: author,
        bookId: pageDetails.bookId,
        pageNumber: pageDetails.bookPageNumber,
        pageId: pageDetails.pageId,
        src: `${pageDetails.pdfUrl}.png`,
        file: `${pageDetails.pdfUrl}.png`,
      }));
      getMetaDataSkills(token);
      // console.log('page id', pageDetails.id);
    }
  }, [props.location.data]);

  const getMetaDataSkills = async (token) => {
    await getSkills(token);
    await getMetaData(token);
  };
  async function getSkills(token) {
    setState((prevState) => ({ ...prevState, isPictureAdded: true }));
    await Axios.post(
      `${process.env.REACT_APP_API_URL}/skills/get`,
      {
        userId: props.location.data.author,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((response) => {
        setState((prevState) => ({
          ...prevState,
          skills: response.data.skills,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getMetaData(token) {
    setState((prevState) => ({ ...prevState, isPictureAdded: true }));
    await Axios.post(
      `${process.env.REACT_APP_API_URL}/pageMetadata/get`,
      {
        pageId: props.location.data.pageDetails.pageId,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((res) => {
        const { data, questions } = res.data.pageMetadata;
        console.log('response meta', data);
        data.forEach((item) => {
          item.editState = false;
          item.frontendId = uuid();
        });
        setState((prevState) => ({
          ...prevState,
          metaData: data,
          question: questions ? parseInt(questions) + 1 : 1,
        }));
      })
      .catch((err) => console.log('error', { err }));
  }

  const logStuff = () => {
    console.log('metaData', state.metaData, 'coordinates', state.coordinates);
  };
  // const changeCoordinate = (coordinates) => {
  //   setState((prevState) => ({ ...prevState, ...coordinates }));
  // };

  // const deleteCoordinate = (coordinates) => {
  //   setState((prevState) => ({ ...prevState, ...coordinates }));
  // };
  const changeCoordinate = (coordinates) => {
    // console.log(coordinates);
    setState((prevState) => ({ ...prevState, coordinates: coordinates }));
  };

  const handleTabChange = (e, newValue) => {
    setState((prevState) => ({ ...prevState, tabValue: newValue }));
  };

  const onChangeText = (text) => {
    setState((prevState) => ({ ...prevState, text }));
  };

  const handleSkillChange = (e) => {
    const {
      target: { value },
    } = e;
    setState((prevState) => ({
      ...prevState,
      skill: typeof value === 'string' ? value.split(',') : value,
    }));
  };

  const showQuestionType = (questionSubType) => {
    return questionSubType === 'practice_integer_english' ? (
      <>Numeric Integer (Mathpix)</>
    ) : questionSubType === 'practice_decimal_english' ? (
      <>Numeric Decimal (Mathpix)</>
    ) : questionSubType === 'practice_character_english' ? (
      <>Character English</>
    ) : questionSubType === 'gujarati_integer' ? (
      <>Numeric Integer Gujarati</>
    ) : questionSubType === 'print' ? (
      <>Print</>
    ) : questionSubType === 'subjective' ? (
      <>Subjective</>
    ) : questionSubType === 'checkbox' ? (
      <>Checkbox</>
    ) : questionSubType === 'name' ? (
      <>Name</>
    ) : questionSubType === 'number' ? (
      <>Number (Print)</>
    ) : (
      <>QR</>
    );
  };
  const applyCustomOrder = (arrayToSort) => {
    let ordering = {},
      sortOrder = ['pageInfo', 'studentInfo', 'question'];
    for (const [index, value] of sortOrder.entries()) ordering[value] = index;
    const tempArray = [...arrayToSort];
    const a = tempArray.sort(function (a, b) {
      return ordering[a.contentType] - ordering[b.contentType];
    });
    return a;
  };

  const validateAnswer = (e) => {
    if (state.contentSubType === 'checkbox') {
      let val = e.target.value;
      let data = val.replace(/[^0-1]/g, '');
      e.target.value = data.slice(0, 4);
      setState((prevState) => ({ ...prevState, answer: e.target.value }));
    } else {
      setState((prevState) => ({ ...prevState, answer: e.target.value }));
    }
    // console.log('field value', data);
  };

  const addMultiJson = () => {
    let {
      coordinates,
      question,
      answer,
      contentType,
      //   questionLabel,
      contentSubType,
      maxScore,
      skill,
      frontendId,
    } = state;
    let qboxFinal = [];
    let ansBoxFinal = [];
    coordinates.forEach((ele, i) => {
      if (i === 0) {
        let qbox = {
          x: Math.round(ele.x),
          y: Math.round(ele.y),
          h: Math.ceil(ele.height),
          w: Math.ceil(ele.width),
        };
        qboxFinal.push(qbox);
      } else {
        let ansBox = {
          x: Math.round(ele.x),
          y: Math.round(ele.y),
          h: Math.ceil(ele.height),
          w: Math.ceil(ele.width),
        };
        ansBoxFinal.push(ansBox);
      }
    });
    // console.log('ans', answer);
    let meta = {
      item: parseFloat(question),
      // label: questionLabel,
      ans: answer.replace(' ', '').split(','),
      modelType: 'mathpix',
      contentType: contentType,
      contentSubType: contentSubType,
      maxScore: maxScore,
      difficulty: 50,
      skillName: skill,
      qbox: qboxFinal,
      ansBox: ansBoxFinal,
      editState: false,
      // frontendId: frontendId,
    };
    state.metaData.push(meta);
    setState((prevState) => ({
      ...prevState,
      text: JSON.stringify(state.metaData, null, 2),
      question: parseInt(prevState.question) + 1,
      questionLabel: '',
      answer: '',
      // contentSubType: '',
      disableSave: false,
      // frontendId: '',
    }));
  };

  const saveAruco = async (e) => {
    const distinct = (value, index, self) => {
      return self.indexOf(value) === index;
    };

    const skillArr = state.metaData.map((item, _index) => {
      return item.skillName;
    });

    const skills = skillArr.filter(distinct);

    let qNumbers = [];
    state.metaData.forEach((item, _index) => {
      delete item.editState;
      // delete item.frontendId;
      qNumbers.push(item.item);
    });

    qNumbers.sort((a, b) => a - b);
    let questionsStart = qNumbers[0];
    const endIndex = qNumbers.length - 1;
    let questionsEnd = qNumbers[endIndex];

    await Axios.post(
      `${process.env.REACT_APP_API_URL}/pageMetadata/add`,
      {
        data: state.metaData,
        dewarp: parseInt(state.dewarp),
        pageId: state.pageId,
        pageNumber: state.pageNumber,
        type: state.type,
        pageType: state.pageType,
        gridHeight: state.gridHeight,
        gridWidth: state.gridWidth,
        questions: state.metaData.length,
        questionsStart: questionsStart,
        questionsEnd: questionsEnd,
        skills: skills,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((response) => {
        props.history.push({
          pathname: `/home/books/${state.book._id}/resources`,
        });
        console.log('response', response);
      })
      .catch((e) => {
        console.log('error', e);
      });
  };

  const handleImport = async (e) => {
    // console.log('handle e', e);
    const { files } = e.target;
    if (files.length === 0) {
      console.log('enter a file');
    } else console.log('files', e.target);

    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], 'UTF-8');
    fileReader.onload = (e) => {
      console.log('file content', JSON.parse(e.target.result));
      setState((prevState) => ({
        ...prevState,
        metaData: JSON.parse(e.target.result),
      }));
      console.log('meta data', state.metaData);
    };
  };

  const deleteQuestion = (questionNo) => {
    let tempIndex = state.metaData.findIndex(
      (question) => question.item === questionNo
    );
    console.log('question', questionNo, tempIndex);
    state.metaData.splice(tempIndex, 1);
    setState((prevState) => ({ ...prevState, metaData: state.metaData }));
  };

  const handleEdit = (question) => {
    // console.log({ question });
    // console.log(state.editState);
    setState((prevState) => ({
      ...prevState,
      tempMetaData: question,
      editState: true,
    }));
    // console.log('somedata', state.metaData);
  };

  const handleAccordionChange = (panel, question) => (event, newExpanded) => {
    // console.log(question.editState);
    // question.editState = false;
    setState((prevState) => ({
      ...prevState,
      accordionState: newExpanded ? panel : false,
      tempMetaData: {},
    }));
  };
  const handleDeleteSnackBar = () => {
    setState((prevState) => ({ ...prevState, deleteSnackbarStatus: false }));
  };

  const validateCrop = () => {
    if (state.contentType === 'question' && state.coordinates.length > 1) {
      if (
        state.contentSubType === 'checkbox' &&
        state.answer.length === 4 &&
        state.question &&
        state.maxScore &&
        state.skill
      ) {
        return true;
      } else if (
        state.contentSubType === 'subjective' &&
        state.answer.length > 1 &&
        state.question &&
        state.maxScore &&
        state.skill
      ) {
        return true;
      }
    }
    return (
      (state.coordinates.length > 1 && //need to revisit
        state.contentType === 'pageInfo') ||
      (state.coordinates.length > 1 && state.contentType === 'studentInfo')
    );
  };
  const validateSave = () => {
    return state.metaData.length > 0;
  };
  return (
    <div className='paper' style={{ marginTop: '0px' }}>
      {/* <Button onClick={logStuff} sx={{ zIndex: 1000 }}>
        logger
      </Button> */}
      <BreadcrumbsItem to={`/home/books/${props.currentBook._id}/editAruco`}>
        Edit Crops
        <HtmlTooltip
          placement='bottom-start'
          title={
            <React.Fragment>
              {/* <Typography color='inherit'>Tooltip with HTML</Typography> */}
              <ul style={{ listStyleType: 'none' }}>
                <li>{'Press d to copy a selected object'}</li>
                <li>
                  {
                    'Hold Shift and drag with left mouse button to select multiple items'
                  }
                </li>
                <li>{'Press r to clear screen'}</li>
              </ul>
            </React.Fragment>
          }
        >
          <IconButton color='secondary' size='small'>
            <InfoRounded fontSize='small' />
          </IconButton>
        </HtmlTooltip>
      </BreadcrumbsItem>
      <Grid
        container
        spacing={6}
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        sx={{ marginTop: '0px' }}
      >
        <Grid
          item
          style={{ position: 'sticky', paddingTop: '0px', paddingLeft: '25px' }}
        >
          {state.isPictureAdded ? (
            // <img
            //   ref={image2Ref}
            //   src={state.file}
            //   alt={state.file.name}
            //   onLoad={() => onImageLoad(imageRef)}
            //   hidden
            // />
            <></>
          ) : (
            // <></>
            <></>
          )}
          {/* <MultiCrops
            ref={imageRef}
            height={state.height}
            width={state.width}
            src={state.file}
            coordinates={state.coordinates}
            onChange={changeCoordinate}
            onDelete={deleteCoordinate}
          /> */}
          <div style={{ border: '1px solid black' }}>
            <KonvaCropper
              stageHeight={state.height}
              stageWidth={state.width}
              imgSrc={state.file}
              coordinates={state.coordinates}
              setState={setState}
              onChange={changeCoordinate}
              metaData={state.tempMetaData}
              editState={state.editState}
            />
          </div>
          <div className='content' style={{ display: 'none' }}>
            <JSONEditorReact
              text={state.text}
              // onChange={handleChange}
              mode={state.mode}
              modes={modes}
              indentation={4}
              onChangeText={onChangeText}
              // onModeChange={onModeChange}
              theme='ace/theme/github'
            />
          </div>
        </Grid>
        <Grid item>
          <CustomDrawer variant='permanent' anchor='right' open={true}>
            <TabContext value={state.tabValue}>
              <Box
                sx={{
                  borderBottom: '1',
                  borderColor: 'divider',
                }}
              >
                <TabList
                  onChange={handleTabChange}
                  sx={{ margin: '0 0 0 30px' }}
                >
                  <Tab label='Add' value='1' />
                  <Tab label='Edit' value='2' />
                </TabList>
                <TabPanel value='1' sx={{ margin: 0, padding: 0 }}>
                  <Grid
                    container
                    style={{ margin: '0px 0px 10px 14px', width: '215px' }}
                    spacing={1}
                  >
                    <Grid item xs={12}>
                      <Typography
                        variant='subtitle1'
                        sx={{ margin: '0 0 10px 0', textAlign: 'center' }}
                      >
                        <b style={{ fontSize: '20px' }}>
                          {state.metaData.length}
                        </b>{' '}
                        Question(s)
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl
                        variant='outlined'
                        required
                        size='medium'
                        fullWidth
                      >
                        <InputLabel id='contentType'>Content Type</InputLabel>
                        {/* {state.contentTypeList.map((item, i) => <>
                        <MenuItem value={item.value}
                        </>)} */}
                        <Select
                          labelId='contentType'
                          value={state.contentType}
                          id='contentType'
                          name='contentType'
                          MenuProps={{
                            disableScrollLock: true,
                          }}
                          onChange={(e) => {
                            setState((prevState) => ({
                              ...prevState,
                              contentType: e.target.value,
                              contentSubType: '',
                              skill: [],
                            }));
                          }}
                          label='Question Type'
                        >
                          <MenuItem value='question' selected>
                            Question
                          </MenuItem>
                          {/* <MenuItem value='studentInfo' selected>
                            Student Info
                          </MenuItem>
                          <MenuItem value='pageInfo' selected>
                            Page Info
                          </MenuItem> */}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl
                        variant='outlined'
                        required
                        size='medium'
                        fullWidth
                      >
                        <InputLabel id='contentSubType'>
                          Content SubType
                        </InputLabel>
                        <Select
                          labelId='contentSubType'
                          value={state.contentSubType}
                          id='contentSubType'
                          name='contentSubType'
                          MenuProps={{
                            disableScrollLock: true,
                          }}
                          onChange={(e) => {
                            setState((prevState) => ({
                              ...prevState,
                              contentSubType: e.target.value,
                              // // frontendId: !state.frontendId.length && uuid(),
                            }));
                          }}
                          label='Content SubType'
                        >
                          {/* <MenuItem value='subjective' selected>
                              Subjective
                            </MenuItem>
                            <MenuItem value='checkbox' selected>
                              Checkbox
                            </MenuItem> */}
                          {/* {state.contentType === 'question' && (
                            <MenuItem value='subjective' selected>
                              Subjective
                            </MenuItem>
                          )} */}
                          {state.contentType === 'question' && (
                            <MenuItem value='checkbox' selected>
                              Checkbox
                            </MenuItem>
                          )}
                          {state.contentType === 'studentInfo' && (
                            <MenuItem value='name' selected>
                              Name
                            </MenuItem>
                          )}
                          {/* {this.state.contentType === 'studentInfo' && (
                              <MenuItem value='number' selected>
                                Number (Print)
                              </MenuItem>
                            )} */}
                          {/* {this.state.contentType === 'pageInfo' && (
                              <MenuItem value='number' selected>
                                Number (Print)
                              </MenuItem>
                            )} */}
                          {state.contentType === 'pageInfo' && (
                            <MenuItem value='qr' selected>
                              QR
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>

                    {/* <Grid item>
                        <TextField
                          variant='outlined'
                          required
                          id='pageNumber'
                          label='Page Number'
                          name='pageNumber'
                          InputProps={{ readOnly: true }}
                          autoComplete='pageNumber'
                          onChange={(e) =>
                            this.setState({ pageNumber: e.target.value })
                          }
                          value={this.state.pageNumber}
                        />
                      </Grid> */}
                    <Grid item xs={12}>
                      <TextField
                        variant='outlined'
                        id='question'
                        label='Question'
                        name='question'
                        type='number'
                        // inputProps={{ inputMode: 'tel' }}
                        // InputProps={{ inputMode: 'numeric', patte: '[0-9]*' }}
                        // className={classes.qbox}
                        size='medium'
                        required
                        fullWidth
                        onChange={(e) =>
                          setState((prevState) => ({
                            ...prevState,
                            question: e.target.value,
                          }))
                        }
                        value={state.question}
                        sx={
                          {
                            // '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button':
                            //   {
                            //     '-webkit-appearance': 'none',
                            //     display: 'none',
                            //   },
                          }
                        }
                      />
                    </Grid>
                    {state.contentType !== 'pageInfo' &&
                      state.contentType !== 'studentInfo' &&
                      state.contentType !== '' && (
                        <>
                          {state.bookType === 'Practice' ? (
                            <>
                              <Grid item xs={12}>
                                <TextField
                                  variant='outlined'
                                  id='answer'
                                  size='medium'
                                  label='Answer'
                                  name='answer'
                                  fullWidth
                                  // className={classes.answerBox}
                                  autoComplete='off'
                                  onChange={(e) => {
                                    validateAnswer(e);
                                    // setState((prevState) => ({
                                    //   ...prevState,
                                    //   answer: e.target.value,
                                    // }));
                                  }}
                                  value={state.answer}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  variant='outlined'
                                  id='maxScore'
                                  label='Max Score'
                                  name='maxScore'
                                  // className={classes.qbox}
                                  size='medium'
                                  type='number'
                                  autoComplete='maxScore'
                                  required
                                  fullWidth
                                  InputProps={{ inputProps: { min: 1 } }}
                                  onChange={(e) =>
                                    setState((prevState) => ({
                                      ...prevState,
                                      maxScore: e.target.value,
                                    }))
                                  }
                                  value={state.maxScore}
                                />
                              </Grid>
                            </>
                          ) : (
                            <></>
                          )}

                          {state.bookType === 'Practice' ? (
                            <Grid item xs={12}>
                              <FormControl
                                variant='outlined'
                                required
                                size='medium'
                                fullWidth
                                // className={classes.formControl}
                              >
                                <InputLabel id='skill'>Skill</InputLabel>
                                <Select
                                  labelId='skill'
                                  value={state.skill}
                                  id='skill'
                                  name='skill'
                                  MenuProps={{
                                    disableScrollLock: true,
                                  }}
                                  onChange={(e) => {
                                    setState((prevState) => ({
                                      ...prevState,
                                      skill: e.target.value,
                                    }));
                                  }}
                                  label='Skill'
                                >
                                  <MenuItem value=''>
                                    <em>None</em>
                                  </MenuItem>
                                  {state.skills
                                    .sort((a, b) =>
                                      a.skillName > b.skillName ? 1 : -1
                                    )
                                    .map((skill, index) => (
                                      <MenuItem
                                        key={index}
                                        value={`${skill.skillName}`}
                                      >
                                        {skill.skillName}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            </Grid>
                          ) : (
                            <></>
                          )}
                        </>
                      )}

                    {/* <Grid item xs={12}>
                        <TextField
                          variant='outlined'
                          id='questionLabel'
                          label='Question Label'
                          name='questionLabel'
                          // className={classes.qbox}
                          size='medium'
                          type='text'
                          autoComplete='questionLabel'
                          required
                          onChange={(e) =>
                            this.setState({
                              questionLabel: e.target.value,
                            })
                          }
                          value={this.state.questionLabel}
                        />
                      </Grid> */}

                    <Grid
                      container
                      direction='row'
                      justifyContent='center'
                      alignItems='center'
                      spacing={2}
                      style={{ margin: '10px 12px 10px 10px' }}
                    >
                      <Grid item xs={6}>
                        <Button
                          variant='contained'
                          disabled={!validateCrop()}
                          // className={classes.uploadButton}
                          color='secondary'
                          onClick={addMultiJson}
                        >
                          Add
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          type='submit'
                          size='medium'
                          variant='contained'
                          color='primary'
                          // className={classes.uploadButton}
                          onClick={saveAruco}
                          disabled={!validateSave()}
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel
                  value='2'
                  style={{ margin: 0, padding: 0 }}
                  // onClick={() => {
                  //   logStuff();
                  // }}
                >
                  <Grid container direction='column'>
                    <Grid
                      item
                      sx={{
                        paddingBottom: '3rem',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                      }}
                    >
                      <Typography
                        variant='subtitle1'
                        sx={{
                          paddingLeft: '10px',
                          marginTop: '5px',
                          textAlign: 'center',
                        }}
                      >
                        Question List:
                      </Typography>
                      {!state.metaData.length && (
                        <Typography
                          sx={{
                            margin: '10px 0 10px 0',
                            textAlign: 'center',
                          }}
                        >
                          No questions made yet.
                        </Typography>
                      )}
                      {state.metaData.length > 0 &&
                        applyCustomOrder(state.metaData).map(
                          (question, index) => {
                            return (
                              <List dense={true} key={index}>
                                <ListItem>
                                  <CustomAccordion
                                    sx={{ width: '100%' }}
                                    expanded={
                                      state.accordionState === question.item
                                    }
                                    onChange={handleAccordionChange(
                                      question.item,
                                      question
                                    )}
                                  >
                                    <CustomAccordionSummary
                                      aria-controls='panel1d-content'
                                      id='panel1d-header'
                                      expandIcon={
                                        <ExpandMore
                                        // onClick={toggleAccordion}
                                        />
                                      }
                                      onClick={() => {
                                        // logStuff();
                                        // console.log('question', question);
                                        handleEdit(question);
                                      }}
                                    >
                                      <Typography
                                        noWrap
                                        sx={{
                                          paddingTop: '7px',
                                          width: '110px',
                                        }}
                                      >
                                        {question.contentType
                                          .charAt(0)
                                          .toUpperCase() +
                                          question.contentType.slice(1)}
                                        :{/* {index + 1} */}
                                        {question.item}
                                      </Typography>
                                      <IconButton
                                        edge='end'
                                        aria-label='delete'
                                        onClick={() =>
                                          deleteQuestion(question.item)
                                        }
                                        sx={{ color: 'red' }}
                                      >
                                        <DeleteOutline />
                                      </IconButton>
                                    </CustomAccordionSummary>

                                    <CustomAccordionDetails
                                      sx={{
                                        marginLeft: '5px',
                                        paddingLeft: '5px',
                                      }}
                                      // onClick={() => {
                                      //   logStuff();
                                      // }}
                                    >
                                      {!!question.ans[0].length && (
                                        <Typography>
                                          Answer : {question.ans}
                                        </Typography>
                                      )}

                                      <Typography
                                        noWrap
                                        sx={{ whiteSpace: 'pre-line' }}
                                      >
                                        Type :{' '}
                                        {showQuestionType(
                                          question.contentSubType
                                        )}
                                        {/* numeric_integer_english */}
                                      </Typography>
                                      {question.skillName && (
                                        <Typography>
                                          {/* Skill : {question.skillName.join(', ')} */}
                                          Skill : {question.skillName}
                                        </Typography>
                                      )}
                                      <Box
                                        sx={{
                                          mt: '1rem',
                                          display: 'flex',
                                          flexDirection: 'row',
                                          justifyContent: 'space-evenly',
                                        }}
                                      ></Box>
                                    </CustomAccordionDetails>
                                  </CustomAccordion>
                                </ListItem>
                              </List>
                            );
                          }
                        )}
                    </Grid>

                    <Grid item sx={{ position: 'fixed', bottom: 0 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          backgroundColor: '#ffffff',
                          width: '240px',
                          pt: 1,
                          pb: 1,
                        }}
                      >
                        <Button
                          variant='contained'
                          color='primary'
                          sx={{ height: '35px' }}
                        >
                          <a
                            href={`data:text/json;charset=utf-8,${encodeURIComponent(
                              JSON.stringify(state.metaData)
                            )}`}
                            download='metaData.json'
                            style={{
                              color: '#ffffff',
                              textDecoration: 'none',
                            }}
                          >
                            Export
                          </a>
                        </Button>
                        <label htmlFor='importJson'>
                          <Input
                            type='file'
                            accept='application/json'
                            id='importJson'
                            name='importJson'
                            aria-label='Select JSON'
                            onChange={handleImport}
                          />
                          <Button
                            variant='contained'
                            component='span'
                            sx={{ height: '35px' }}
                          >
                            Import
                          </Button>
                        </label>
                      </Box>
                    </Grid>
                  </Grid>
                </TabPanel>
              </Box>
            </TabContext>
          </CustomDrawer>
        </Grid>
      </Grid>
      {state.deleteSnackbarStatus && (
        <Snackbar
          open={state.deleteSnackbarStatus}
          autoHideDuration={4000}
          onClose={handleDeleteSnackBar}
        >
          <Alert
            variant='filled'
            elevation={6}
            severity='success'
            onClose={handleDeleteSnackBar}
          >
            Deleted Successfully
          </Alert>
        </Snackbar>
      )}
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  currentBook: selectCurrentBook,
});

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(EditAruco)
);
// export default connect(mapStateToProps)(EditAruco);
