import { LockOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import CustomErrorTemplate from "../../elements/CustomErrorTemplate";
import CustomSuccessTemplate from "../../elements/CustomSuccessTemplate";
import { logout } from "../../../redux/user/userAction";

const RegisterPage = (props) => {
  const [inputs, setInput] = useState({
    firstName: "",
    lastName: "",
    organization: "",
    username: "",
    email: "",
    password: "",
    userType: "author",
  });
  const [state, setState] = useState({
    success: false,
    error: false,
    loading: false,
    message: "",
    isSignedUp: false,
  });

  // const registering = useSelector(state => state.registration.registering);
  const dispatch = useDispatch();
  // const state = useSelector((state) => state);
  // delete state.user.error;
  // reset login status
  useEffect(() => {
    console.log("logged out");
    dispatch(logout());
  }, [dispatch]);

  function handleChange(e) {
    const { name, value } = e.target;
    setInput((inputs) => ({ ...inputs, [name]: value }));
  }

  // 'https://dev.smartpaperapp.com/v1/auth/signUp'
  const handleSubmit = async (e) => {
    const { firstName, lastName, organization, email, password, username } =
      inputs;
    e.preventDefault();
    if (firstName && lastName && email && password && username) {
      // dispatch(register(inputs));
      await axios
        .post(`${process.env.REACT_APP_AUTH_API_URL}/auth/signup`, {
          email,
          username,
          password,
          name: `${firstName} ${lastName}`,
          userType: "author",
          organization,
          organizationId: "62ea3dd91621afd677e300ee",
        })
        .then(() => {
          setState({
            ...state,
            success: true,
            error: false,
            loading: false,
            message: "You are successfully registered.",
          });
          setTimeout(() => {
            props.history.push("/login");
            setState({
              ...state,
              isSignedUp: true,
            });
          }, 3500);
        })
        .catch(() => {
          setState({
            ...state,
            loading: false,
            success: false,
            error: true,
          });
        });
    }
  };

  const validateForm = () => {
    const { email, username, password } = inputs;
    return (
      password.trim().length > 0 &&
      email.trim().length > 0 &&
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email) &&
      username.trim().length > 0 &&
      /^^[a-zA-Z][a-zA-Z0-9_]{7,29}$/i.test(username)
    );
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>
        <Container component="main" maxWidth="xs">
          <Box
            style={{
              marginTop: "20%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Avatar style={{ margin: 1, bgcolor: "primary" }}>
              <LockOutlined />
            </Avatar>
            <Typography component="h1" variant="h5" gutterBottom>
              Sign up
            </Typography>
            <Box style={{ marginTop: "16px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl style={{ width: "100%" }}>
                    <TextField
                      autoComplete="fname"
                      name="firstName"
                      required
                      fullWidth
                      id="firstName"
                      value={inputs.firstName}
                      label="First Name"
                      autoFocus
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl style={{ width: "100%" }}>
                    <TextField
                      required
                      fullWidth
                      id="lastName"
                      value={inputs.lastName}
                      label="Last Name"
                      name="lastName"
                      autoComplete="lname"
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl style={{ width: "100%" }}>
                    <TextField
                      required
                      fullWidth
                      id="organization"
                      label="Organization"
                      value={inputs.organization}
                      name="organization"
                      autoComplete="organization"
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl style={{ width: "100%" }}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      value={inputs.email}
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl style={{ width: "100%" }}>
                    <TextField
                      required
                      fullWidth
                      id="username"
                      value={inputs.username}
                      label="username"
                      name="username"
                      autoComplete="username"
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl style={{ width: "100%" }}>
                    <TextField
                      required
                      fullWidth
                      value={inputs.password}
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="new-password"
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Button
                onClick={handleSubmit}
                fullWidth
                // loading={loading}
                color="primary"
                variant="contained"
                style={{ marginTop: "16px", marginBottom: "8px" }}
                disabled={!validateForm()}
              >
                Sign Up
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link to="/login">{"Already have an account? Sign in"}</Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          {state.success && (
            <CustomSuccessTemplate message="Yayy! You are succesfully signed up!" />
          )}
          {state.error && (
            <CustomErrorTemplate message="Oops! Error Signing up!" />
          )}
        </Container>
      </Grid>
    </Grid>
  );
};

export { RegisterPage };
