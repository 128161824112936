/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable no-prototype-builtins */
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import Axios from 'axios';
import MaterialTable from 'material-table';
import React, { Component } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectCurrentBook } from '../../../redux/book/bookSelector';
import { selectCurrentUser } from '../../../redux/user/userSelector';

const styles = () => ({
  root: {
    textAlign: 'center',
  },
  heading: {
    paddingBottom: '0px',
    paddingTop: '16px',
    color: '',
    textAlign: 'center',
  },
  input: {
    display: 'none',
  },
  uploadButton: {
    background: '#005792',
    borderRadius: '2px',
    color: '#FBFAF2',
    fontFamily: 'Rubik',
    fontWeight: '400',
    textAlign: 'center',
    fontSize: 14,
    textTransform: 'none',
    width: '150px',
  },
  fileButton: {
    background: '#005792',
    borderRadius: '2px',
    color: '#FBFAF2',
    fontFamily: 'Rubik',
    fontWeight: '400',
    textAlign: 'center',
    fontSize: 14,
    textTransform: 'none',
  },
  progress: {
    maxWidth: '100px',
    width: '80px',
    height: '80px',
  },
  inputFields: {
    minWidth: '400px',
    maxWidth: '100%',
  },
});

class AddTest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      testName: '',
      dataEndpoint: '',
      precisionMode: false,
      dataExportFormat: 'simple',
      testType: 'practice',
      itemPdf: [],
      csvFile: [],
      pdfPages: 0,
      ansRow: 1,
      pdfFile: [],
      skillsPractised: 0,
      totalQuestions: 0,
      loading: false,
      book: [],
      unused: [],
      data: [],
      author: [],
      isPDFAdded: false,
      isCSVAdded: false,
      answerCsv: [],
      chapter: '',
      pages: [],
      actions: [
        {
          tooltip: 'Add All Selected Pages to Test',
          icon: 'save',
          onClick: (evt, data) => this.addSelection(data),
        },
      ],
      loadingMessage: '',
    };
  }

  componentDidMount() {
    if (typeof this.props.location.data === 'undefined') {
      this.props.history.push({
        pathname: '/home/books',
      });
    } else {
      const { currentBook, currentUser } = this.props;
      const token = JSON.parse(localStorage.getItem('token'));
      this.setState({ book: currentBook, author: currentUser }, () => {
        this.getUnusedPages(token);
        this.getBookPages(token);
      });
      //   this.setState({ author: currentUser }, () => this.getBookPages());
    }
  }

  addSelection = (data) => {
    let pages = [];
    data.forEach((element) => {
      pages.push(element);
    });
    this.setState({ pages: pages });
  };

  fetchDetails = () => {
    this.state.data.forEach((item) => {
      item.pageNumber = this.state.bookPages[item.pageNumber];
    });
  };

  // 'https://prod.paperflowapp.com/authoring-book/author/book/getBookPages'

  getBookPages = async (token) => {
    await Axios.post(
      `${process.env.REACT_APP_API_URL}/book/getBookPages`,
      {
        bookId: this.state.book._id,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((response) => {
        if (response.data.bookPage.length > 0) {
          this.setState({ data: response.data.bookPage });
        } else {
          this.setState({
            data: response.data.bookPage,
            loading: false,
            loadingMessage: 'No Pages Found, Please Add Pages to Book',
          });
        }
      })
      .catch(() => {
        this.setState({
          loading: false,
          loadingMessage: 'No Page Found, please add some pages',
        });
      });
  };
  //  'https://prod.paperflowapp.com/authoring-page-metadata/pagemetadata/unusedPages'
  getUnusedPages = async (token) => {
    await Axios.post(
      `${process.env.REACT_APP_API_URL}/pageMetadata/getUnusedPages`,
      {
        bookId: this.state.book._id,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((response) => {
        const { unusedPages } = response.data;
        let temp = {};
        unusedPages.forEach((aruco, index) => {
          const { pageId } = aruco;
          temp[index] = pageId;
        });
        this.setState({ unused: temp });

        // this.setState({ arucos: response.data.arucos });
      })
      .catch(() => {});
  };

  /* verifyTest = () => {
    // eslint-disable-next-line array-callback-return
    this.state.data.map((page) => {
      if (
        page.tableData.hasOwnProperty("checked") &&
        page.tableData.checked === true
      ) {
        // console.log(page);
      }
    });
  }; */

  saveTest = async (e) => {
    e.preventDefault();
    let pageIds = [];
    // console.log(
    //   'this data',
    //   this.state.data,
    //   this.state.dataEndpoint,
    //   this.state.dataExportFormat,
    //   this.state.testType,
    //   this.state.precisionMode,
    //   this.state.book._id
    // );
    this.state.data.forEach((page) => {
      if (
        page.tableData.hasOwnProperty('checked') &&
        page.tableData.checked === true
      ) {
        this.state.pages.push(page);
        pageIds.push(page.pageId);
      }
    });

    //todo: modify this
    this.props.history.push({
      pathname: `/home/books/${this.props.currentBook._id}/tests/viewTest`,
      data: {
        book: this.state.book,
        pages: this.state.pages,
        test: {
          testName: this.state.testName,
          testType: this.state.testType,
          bookId: this.state.book._id,
          dataEndpoint: this.state.dataEndpoint,
          precisionMode: this.state.precisionMode,
          dataExportFormat: this.state.dataExportFormat,
        },
        pageIds: pageIds,
        author: this.state.author,
        type: 'create',
      },
    });
  };
  // isValidUrl = urlString => {
  // 	let url;
  // 	try {
  //       	url =new URL(urlString);
  //     }
  //     catch(e){
  //       return false;
  //     }
  //     return url.protocol === "http:" || url.protocol === "https:";
  // }
  isValidUrl = (urlString) => {
    let url;
    try {
      url = new URL(urlString);
    } catch (_) {
      return false;
    }
    return url.protocol === 'http:' || url.protocol === 'https:';
  };

  validateCreateTest = () => {
    const { dataEndpoint, testName } = this.state;
    if (dataEndpoint.length > 0 && testName.length > 0) {
      return this.isValidUrl(dataEndpoint);
    }
    return testName.length > 0 && !dataEndpoint.length;
  };

  render() {
    const classes = styles();

    return (
      <>
        <BreadcrumbsItem
          to={`/home/books/${this.props.currentBook._id}/tests/addTest`}
        >
          Add Test
        </BreadcrumbsItem>
        <Paper
          sx={{
            mb: 3,
            paddingBottom: 3,
            minWidth: '500px',
            maxWidth: '80%',
            width: '100%',
            flexGrow: 1,
          }}
          component='form'
        >
          <Typography
            variant='h5'
            component='h5'
            gutterBottom
            mt={1}
            sx={{ textAlign: 'center' }}
          >
            Add Assessment
          </Typography>
          <Grid
            spacing={2}
            container
            direction='column'
            justifyContent='center'
            alignItems='center'
          >
            <Grid item xs={12} sx={{ width: '100%' }}>
              <Grid
                container
                direction='row'
                spacing={2}
                justifyContent='space-evenly'
              >
                <Grid item md={5} xs={6}>
                  <TextField
                    // margin='normal'
                    required
                    // size='medium'
                    autoComplete='off'
                    fullWidth
                    type='text'
                    id='testName'
                    name='testName'
                    value={this.state.testName}
                    label='Name'
                    onChange={(e) =>
                      this.setState({ testName: e.target.value })
                    }
                  />
                </Grid>
                <Grid item md={5} xs={6}>
                  <FormControl variant='outlined' required fullWidth>
                    <InputLabel id='testType'>Type</InputLabel>
                    <Select
                      labelId='testType'
                      value={this.state.testType}
                      id='testType'
                      name='testType'
                      onChange={(e) =>
                        this.setState({ testType: e.target.value })
                      }
                      label='Type'
                      defaultValue='practice'
                    >
                      <MenuItem value='practice'>Practice</MenuItem>
                      {/* <MenuItem value='survey'>Survey</MenuItem> */}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={5} xs={6}>
                  <TextField
                    required
                    fullWidth
                    type='url'
                    id='dataEndpoint'
                    name='dataEndpoint'
                    value={this.state.dataEndpoint}
                    label='Data Endpoint'
                    onChange={(e) => {
                      this.setState({ dataEndpoint: e.target.value });
                    }}
                  />
                </Grid>
                <Grid item md={5} xs={6}>
                  <FormControl variant='outlined' required fullWidth>
                    <InputLabel id='precisionMode'>Precision Mode</InputLabel>
                    <Select
                      labelId='precisionMode'
                      value={this.state.precisionMode}
                      id='precisionMode'
                      name='precisionMode'
                      onChange={(e) =>
                        this.setState({ precisionMode: e.target.value })
                      }
                      label='Precision Mode'
                      defaultValue={false}
                    >
                      <MenuItem value={true}>On</MenuItem>
                      <MenuItem value={false}>Off</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={5} xs={6} sx={{ margin: 'auto' }}>
                  <FormControl variant='outlined' required fullWidth>
                    <InputLabel id='dataExportFormat'>
                      Data Export Format
                    </InputLabel>
                    <Select
                      labelId='dataExportFormat'
                      value={this.state.dataExportFormat}
                      id='dataExportFormat'
                      name='dataExportFormat'
                      onChange={(e) =>
                        this.setState({ dataExportFormat: e.target.value })
                      }
                      label='Data Export Format'
                      defaultValue='simple'
                    >
                      <MenuItem value='simple'>Simple</MenuItem>
                      <MenuItem value='detailed'>Detailed</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs sm>
              <MaterialTable
                style={{
                  minWidth: '300px',
                  maxWidth: '100%',
                  textAlign: 'center',
                }}
                localization={{
                  body: {
                    emptyDataSourceMessage: this.state.loadingMessage,
                    editRow: {
                      deleteText: 'Are you sure you want to delete?',
                    },
                    fontSize: '12px',
                  },
                }}
                options={{
                  paging: true,
                  search: false,
                  actionsColumnIndex: -1,
                  selection: true,
                }}
                title='Select smart pages'
                columns={[
                  {
                    title: 'PAGE NUMBER',
                    field: 'bookPageNumber',
                    lookup: this.state.lookup,
                  },
                  {
                    title: 'THUMBNAIL',
                    field: 'pdfUrl',
                    editable: 'never',
                    render: (rowData) => (
                      <div
                        style={{
                          display: 'inline-block',
                          verticalAlign: 'top',
                        }}
                      >
                        <img
                          src={`${rowData.pdfUrl}.png`}
                          alt={rowData.pageNumber}
                          style={{ width: 100, height: 120 }}
                        />
                      </div>
                    ),
                  },
                ]}
                data={this.state.data}
                actions={this.state.actions}
              />
            </Grid>
            <Grid item mt={2} mb={1}>
              <Button
                disabled={!this.validateCreateTest()}
                width='auto'
                variant='contained'
                color='primary'
                className='submitButton'
                onClick={(e) => this.saveTest(e)}
              >
                Create Assessment
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  currentBook: selectCurrentBook,
});

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(AddTest)
);
