export const UserActionTypes = {
  ALERT_SUCCESS: 'ALERT_SUCCESS',
  ALERT_ERROR: 'ALERT_ERROR',
  ALERT_CLEAR: 'ALERT_CLEAR',

  SET_CURRENT_USER: 'SET_CURRENT_USER',
  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  LOGOUT: 'USERS_LOGOUT',

  GETALL_REQUEST: 'USERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'USERS_GETALL_FAILURE',

  DELETE_REQUEST: 'USERS_DELETE_REQUEST',
  DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
  DELETE_FAILURE: 'USERS_DELETE_FAILURE',

  FORGOT_REQUEST: 'USERS_FORGOT_REQUEST',
  FORGOT_SUCCESS: 'USERS_FORGOT_SUCCESS',
  FORGOT_FAILURE: 'USERS_FORGOT_FAILURE',

  CHANGE_PASSWORD_REQUEST: 'USERS_CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'USERS_CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'USERS_CHANGE_PASSWORD_FAILURE',
};
