import { UserActionTypes } from './userTypes';
import { userService } from '../../Utility/api.service';

export const setCurrentUser = (user) => ({
  type: UserActionTypes.SET_CURRENT_USER,
  payload: user,
});

export const logout = () => {
  //   userService.logout();
  localStorage.removeItem('user');
  return { type: UserActionTypes.LOGOUT };
};

export const register = (user) => {
  return (dispatch) => {
    dispatch(request(user));

    userService.register(user).then(
      () => {
        dispatch(success());
        // history.push('/login');
        // dispatch(alertActions.success('Registration successful'));
      },
      (error) => {
        dispatch(failure(error.toString()));
        // dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: UserActionTypes.REGISTER_REQUEST, payload: user };
  }
  function success(user) {
    return { type: UserActionTypes.REGISTER_SUCCESS, payload: user };
  }
  function failure(error) {
    return { type: UserActionTypes.REGISTER_FAILURE, error };
  }
};

export const forgot = (email) => {
  return (dispatch) => {
    dispatch(request(email));

    userService.forgot(email).then(
      () => {
        dispatch(success());
        // history.push('/login');
        // dispatch(alertActions.success('Link sent to your registered Email id'));
      },
      (error) => {
        dispatch(failure(error.toString()));
        // dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(email) {
    return { type: UserActionTypes.FORGOT_REQUEST, payload: email };
  }
  function success(email) {
    return { type: UserActionTypes.FORGOT_SUCCESS, payload: email };
  }
  function failure(error) {
    return { type: UserActionTypes.FORGOT_FAILURE, error };
  }
};
