/* eslint-disable simple-import-sort/imports */
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import CustomError from '../elements/CustomError';
import CustomLoader from '../elements/CustomLoader';

import { selectCurrentUser } from '../../redux/user/userSelector';

//TODO: Change Private Route for dashboard access!
const PrivateRoute = ({ currentUser, component: Component, ...restProps }) => {
  return (
    <Route
      {...restProps}
      component={(props) => {
        return (
          <>
            {currentUser ? (
              <div>
                {currentUser ? (
                  <div>
                    <Component {...props} />
                  </div>
                ) : (
                  <div>
                    <CustomError
                      errorCode='403'
                      errorMessage={`You are not allowed to access this page!`}
                      hint={`Please Login and Try Again!`}
                      errorRoute={`/`}
                      routeText={`Go To Login`}
                    />
                  </div>
                )}
              </div>
            ) : currentUser == null ? (
              <div>
                <CustomError
                  errorCode='403'
                  errorMessage={`You are not allowed to access this page!`}
                  hint={`Please Login and Try Again!`}
                  errorRoute={`/`}
                  routeText={`Go To Login`}
                />
                <Redirect to='/' />
              </div>
            ) : (
              <CustomLoader />
            )}
          </>
        );
      }}></Route>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(PrivateRoute);
