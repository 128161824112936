import { AddCircleOutlineRounded } from '@mui/icons-material';
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import Axios from 'axios';
import React, { Component } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectCurrentBook } from '../../../redux/book/bookSelector';
import { selectCurrentUser } from '../../../redux/user/userSelector';

const token = JSON.parse(localStorage.getItem('token'));

const styles = () => ({
  root: {
    maxWidth: 345,
  },
  addIcon: {
    display: 'block',
    textAlign: 'center',
    alignItems: 'center',
    maxWidth: 345,
  },
});
class Arucos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      book: [],
      author: [],
      arucos: [],
    };
  }

  componentDidMount() {
    if (this.props.location.data === undefined) {
      this.props.history.push({
        pathname: `/home/books`,
      });
    } else {
      const { book, author } = this.props.location.data;
      this.setState({ book: book, author: author }, () => this.getArucos());
    }
  }

  getArucos = () => {
    Axios.post(
      'https://prod.paperflowapp.com/author-aruco/aruco/get',
      {
        bookId: this.state.book._id,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((response) => {
        this.setState({ arucos: response.data.arucos });
      })
      .catch(() => {});
  };
  render() {
    const { classes } = this.props;
    const { pathname } = this.props.location;
    return (
      <>
        <BreadcrumbsItem
          to={`/home/books/${this.props.currentBook._id}/arucos`}>
          Crops
        </BreadcrumbsItem>
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='center'
          spacing={4}>
          <Grid item>
            <Card className={classes.addIcon}>
              <CardHeader title='Generate New Aruco' />
              <CardActionArea
                onClick={() =>
                  this.props.history.push({
                    pathname: `${pathname}/addAruco`,
                    data: {
                      book: this.state.book,
                      author: this.state.author,
                    },
                  })
                }>
                <AddCircleOutlineRounded fontSize='large' color='primary' />
                <CardContent>
                  <Typography
                    variant='body2'
                    color='textSecondary'
                    component='p'>
                    You can create arucos for tests here.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          {this.state.arucos.map((aruco, index) => (
            <Grid item key={index}>
              <Card className={classes.root}>
                <CardActionArea>
                  <CardContent>
                    <Typography gutterBottom variant='h5' component='h2'>
                      Aruco: {aruco.arucoId}
                    </Typography>
                    <Typography
                      variant='body2'
                      color='textSecondary'
                      component='p'>
                      Type: {aruco.type}
                    </Typography>
                    <Typography
                      variant='body2'
                      color='textSecondary'
                      component='p'>
                      Question Start: {aruco.questionsStart}
                    </Typography>
                    <Typography
                      variant='body2'
                      color='textSecondary'
                      component='p'>
                      Question Ends: {aruco.questionsEnd}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Typography
                    style={{ marginLeft: 'auto' }}
                    variant='subtitle2'>
                    Total Questions: {aruco.questions}
                  </Typography>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  currentBook: selectCurrentBook,
});

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(Arucos)
);
