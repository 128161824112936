/* eslint-disable no-import-assign */
import { Description, Done } from '@mui/icons-material';
import { Button, Grid, Paper, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import * as Papa from 'papaparse';
import React, { Component } from 'react';

const styles = () => ({
  input: {
    display: 'none',
  },
  uploadButton: {
    background: '#005792',
    borderRadius: '2px',
    color: '#FBFAF2',
    fontFamily: 'Rubik',
    fontWeight: '400',
    textAlign: 'center',
    fontSize: 14,
    textTransform: 'none',
    width: '150px',
  },
  fileButton: {
    background: '#005792',
    borderRadius: '2px',
    color: '#FBFAF2',
    fontFamily: 'Rubik',
    fontWeight: '400',
    textAlign: 'center',
    fontSize: 14,
    textTransform: 'none',
  },
  progress: {
    maxWidth: '100px',
    width: '80px',
    height: '80px',
  },
});

// const token = JSON.parse(localStorage.getItem('token'));
class AddRecoTest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      testCsv1: [],
      testCsv2: [],
      testRow: 0,
    };
  }

  handleFile1 = async (e) => {
    var file = e.target.files[0];
    Papa.parsePromise = function (file) {
      return new Promise(function (complete, error) {
        Papa.parse(file, {
          complete,
          error,
          skipEmptyLines: true,
          header: true,
        });
      });
    };

    await Papa.parsePromise(file).then((results) => {
      this.setState({ testCsv1: results.data, testRow: results.data.length });
    });
  };
  handleFile2 = async (e) => {
    var file = e.target.files[0];
    Papa.parsePromise = function (file) {
      return new Promise(function (complete, error) {
        Papa.parse(file, {
          complete,
          error,
          skipEmptyLines: true,
          header: true,
        });
      });
    };

    await Papa.parsePromise(file).then((results) => {
      this.setState({ testCsv2: results.data, testRow: results.data.length });
    });
  };

  validateCreateTest = () => {
    return this.state.testCsv1.length > 0 && this.state.testCsv2.length > 0;
  };

  addRecTest = () => {
    this.props.history.push({
      pathname: '/viewRecoTest',
      data: {
        book: this.state.book,
        testCsv1: this.state.testCsv1,
        testCsv2: this.state.testCsv2,
        type: 'create',
      },
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <form style={{ width: '100%' }}>
          <Typography variant='h5'>Create Test</Typography>
          <Paper style={{ margin: 'auto', maxWidth: '600px' }}>
            <Grid
              spacing={1}
              container
              direction='column'
              justifyContent='center'
              alignItems='center'>
              <Grid item>
                <div className='thumbnailUpload'>
                  <div className='uploader'>
                    <input
                      required
                      accept='.csv'
                      className={classes.input}
                      id='testCsv1'
                      type='file'
                      onChange={this.handleFile1}
                    />
                    <label htmlFor='testCsv1' className='custom-file-upload'>
                      <Button
                        variant='contained'
                        className={classes.fileButton}
                        color='primary'
                        component='span'
                        startIcon={<Description />}>
                        Select File
                      </Button>
                    </label>
                  </div>
                  <div>
                    {this.state.isThumbnailUploaded ? (
                      <Done style={{ color: '#4BB543' }} />
                    ) : (
                      <Button
                        disabled={!this.state.isThumbnailAdded}
                        width='auto'
                        variant='contained'
                        color='primary'
                        className={classes.uploadButton}
                        onClick={this.handleFile1}>
                        Upload Adaptive Test CSV 1
                      </Button>
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item>
                <div className='thumbnailUpload'>
                  <div className='uploader'>
                    <input
                      required
                      accept='.csv'
                      className={classes.input}
                      id='testCsv2'
                      type='file'
                      onChange={this.handleFile2}
                    />

                    <label htmlFor='testCsv2' className='custom-file-upload'>
                      <Button
                        variant='contained'
                        className={classes.fileButton}
                        color='primary'
                        component='span'
                        startIcon={<Description />}>
                        Select File
                      </Button>
                    </label>
                  </div>
                  <div>
                    {this.state.isThumbnailUploaded ? (
                      <Done style={{ color: '#4BB543' }} />
                    ) : (
                      <Button
                        disabled={!this.state.isThumbnailAdded}
                        width='auto'
                        variant='contained'
                        color='primary'
                        className={classes.uploadButton}
                        onClick={this.uploadThumbnail}>
                        Upload Adaptive Test CSV 2
                      </Button>
                    )}
                  </div>
                </div>
              </Grid>
              {/* <Grid item>
                                <div style={{ display: 'inline', padding: '5px', width: '300px' }}>
                                    <Typography variant="button" gutterBottom> Adaptive Test CSV1 : </Typography>
                                    <input type="file" style={{ marginTop: '10px', marginLeft: '10px' }} variant="outline" className="file-upload file-select" id="testCsv1" name="testCsv1" accept=".csv" onChange={e => this.handleFile1(e)} />
                                </div>
                            </Grid>
                            <Grid item>
                                <div style={{ display: 'inline-block', padding: '5px', width: '300px' }}>
                                    <Typography variant="button" gutterBottom> Adaptive Test CSV : </Typography>
                                    <input type="file" style={{ marginTop: '10px', marginLeft: '10px' }} variant="outline" className="file-upload file-select" id="testCsv2" name="testCsv2" accept=".csv" onChange={e => this.handleFile2(e)} />
                                </div>
                            </Grid> */}
              <Grid item>
                <Button
                  disabled={!this.validateCreateTest()}
                  width='auto'
                  onClick={() => this.addRecTest()}
                  variant='contained'
                  color='secondary'>
                  Create Recommended Test
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </form>
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(AddRecoTest);
