/* eslint-disable react-hooks/exhaustive-deps */
import { LockOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  FormControl,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import Axios from "axios";
import React, { useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import CustomErrorTemplate from "../../elements/CustomErrorTemplate";
import CustomSuccessTemplate from "../../elements/CustomSuccessTemplate";
import loginSideImage from "../../../Assets/login_side.jpg";
import { setCurrentUser } from "../../../redux/user/userAction";

const LoginPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState({
    username: "",
    password: "",
  });
  const [state, setState] = useState({
    success: false,
    error: false,
    loading: false,
    message: "",
    isLoggedIn: false,
  });

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  // 'https://dev.smartpaperapp.com/v1/auth/login'
  async function handleSubmit(e) {
    e.preventDefault();
    setState({ ...state, loading: true });

    if (inputs.username && inputs.password) {
      await Axios.post(`${process.env.REACT_APP_AUTH_API_URL}/auth/login`, {
        username: inputs.username,
        password: inputs.password,
        userType: "author",
      })
        .then((res) => {
          setState({
            ...state,
            success: true,
            error: false,
            loading: false,
            isLoggedIn: true,
            message: "Login Successful",
          });
          setTimeout(() => {
            const { token, user } = res.data;
            localStorage.setItem("token", JSON.stringify(token));
            localStorage.setItem("user", JSON.stringify(user));
            dispatch(setCurrentUser(user));
            setCurrentUser(user);
            history.push("/home", {
              user: user,
              token: token,
            });
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
          setState({
            ...state,
            loading: false,
            success: false,
            error: true,
            message: err.response.data.message,
          });
          setTimeout(() => {
            setState({ ...state, error: false, message: "" });
          }, 3000);
        });
    }
  }

  const validateForm = () => {
    const { username, password } = inputs;
    return (
      password.trim().length > 0 &&
      username.trim().length > 0 &&
      /^^[a-zA-Z][a-zA-Z0-9_]{4,29}$/i.test(username)
    );
  };

  return (
    <Grid container component="main" style={{ height: "100vh" }}>
      <Grid
        item
        xs={false}
        sm={6}
        md={6}
        lg={7}
        style={{
          backgroundImage: `url(${loginSideImage})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={6} md={6} lg={5} component={Paper} elevation={6}>
        <Box
          style={{
            margin: "20% 10px auto 10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar style={{ margin: 8, bgcolor: "#FFFFFF" }}>
            <LockOutlined />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box style={{ marginTop: "auto" }}>
            {/* <FormControl style={{ width: "100%" }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                value={inputs.email}
                autoComplete="email"
                autoFocus
                onChange={handleChange}
              />
            </FormControl> */}
            <FormControl style={{ width: "100%" }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="username"
                name="username"
                value={inputs.username}
                autoComplete="username"
                autoFocus
                onChange={handleChange}
              />
            </FormControl>
            <FormControl style={{ width: "100%" }}>
              {/* todo: Password visibility to be added before deploying */}
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                value={inputs.password}
                autoComplete="current-password"
                onChange={handleChange}
                onKeyDown={(e) => e.key === "Enter" && handleSubmit(e)}
              />
            </FormControl>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              style={{ marginTop: "16px", marginBottom: 4 }}
              onClick={(e) => handleSubmit(e)}
              onKeyDown={(e) => e.key === "Enter" && handleSubmit(e)}
              disabled={!validateForm() || state.loading || state.isLoggedIn}
            >
              {state.loading && (
                <span style={{ padding: "0 10px 0 0 " }}> Logging In. . .</span>
              )}
              {!state.loading && <span>Login</span>}
              {state.loading && (
                <TailSpin
                  style={{ paddingTop: "2px" }}
                  color="#00BFFF"
                  height={15}
                  width={15}
                />
              )}
            </Button>

            <Grid container style={{ marginTop: 4 }}>
              <Grid item xs>
                <Link to="/forgot-password">Forgot password?</Link>
              </Grid>
              <Grid item>
                <Link to="/register">{"Don't have an account? Sign Up"}</Link>
              </Grid>
            </Grid>
          </Box>
          {state.success && (
            <CustomSuccessTemplate message="Yayy! You are succesfully logged in!" />
          )}
          {state.error && <CustomErrorTemplate message={state.message} />}
        </Box>
      </Grid>
    </Grid>
  );
};

export default LoginPage;

// export default withStyles(styles, { withTheme: true })(Login);
