import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { setCurrentUser } from './redux/user/userAction';
import { selectCurrentUser } from './redux/user/userSelector';
import Routes from './router/Routes';
import theme from './theme';

class App extends Component {
  componentDidMount() {
    const { setCurrentUser } = this.props;

    var userAuth = JSON.parse(localStorage.getItem('user'));
    setCurrentUser(userAuth);
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className='App'>
          <Routes />
        </div>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
