/* eslint-disable react-hooks/exhaustive-deps */
import { LockOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import CustomErrorTemplate from "../../elements/CustomErrorTemplate";
import CustomSuccessTemplate from "../../elements/CustomSuccessTemplate";

const ResetSuccess = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        padding: "40px 0",
        height: "100vh",
        marginTop: 100,
      }}
    >
      <Paper
        elevation={3}
        style={{
          maxWidth: "400px",
          width: "340px",
          // margin: "100px auto auto auto",
          padding: "60px 40px",
          background: "white",
          borderRadius: "4px",
          boxShadow: "0 2px 3px #C8D0D8",
          display: "inline-block",
          margin: "20px auto",
        }}
      >
        <div
          style={{
            borderRadius: "200px",
            height: "200px",
            width: "200px",
            background: "#F8FAF5",
            margin: "0 auto",
          }}
        >
          <i
            style={{
              color: "#9ABC66",
              fontSize: "100px",
              lineHeight: "200px",
              marginLeft: "-15px",
            }}
          >
            ✓
          </i>
        </div>
        <h1>Success</h1>
        <p>
          We successfully reset your password.
          <br /> Please try logging in!
        </p>
      </Paper>
    </Box>
  );
};
const ResetPassword = (props) => {
  const [state, setState] = useState({
    default: true,
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
    user: {},
  });
  const [inputs, setInput] = useState({
    password: "",
    confirmPassword: "",
  });
  let { userId, token } = props.match.params;

  // 'https://dev.smartpaperapp.com/smart-user/get'
  useEffect(() => {
    async function getUser() {
      await axios
        .post(`${process.env.REACT_APP_AUTH_API_URL}/user/get`, {
          userId: userId,
          userType: "author",
        })
        .then((res) => {
          const { resetToken } = res.data.user;
          if (resetToken !== token) {
            props.history.push("/login");
          }
          setState((prevState) => ({
            ...prevState,
            user: res.data.user,
          }));
        })
        .catch(() => {});
    }
    getUser();
  }, [userId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput((input) => ({ ...input, [name]: value }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const { password, confirmPassword } = inputs;
    setState((prevState) => ({
      ...prevState,
      loading: true,
      success: false,
      error: false,
    }));

    // https://dev.smartpaperapp.com/v1/auth/resetPassword
    if (password === confirmPassword) {
      await axios
        .post(`${process.env.REACT_APP_AUTH_API_URL}/auth/password/reset`, {
          userId: userId,
          password: inputs.password,
        })
        .then(() => {
          setState((prevState) => ({
            ...prevState,
            default: false,
            success: true,
            error: false,
          }));
        })
        .catch((err) => {
          setState((prevState) => ({
            ...prevState,
            default: true,
            error: true,
            success: false,
            errorMessage: err.response.data.message,
          }));
        });
    } else {
      setState((prevState) => ({
        ...prevState,
        loading: false,
        error: true,
        errorMessage: "Password and Confirm Password does not match",
      }));
    }
  };

  const validateForm = () => {
    const { password, confirmPassword } = inputs;
    return (
      password.trim().length > 0 &&
      confirmPassword.trim().length > 0 &&
      password === confirmPassword
    );
  };
  return (
    <Container component="main" maxWidth="xs">
      {state.default ? (
        <Box
          style={{
            marginTop: 100,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ margin: 1, bgcolor: "primary.main" }}>
            <LockOutlined />
          </Avatar>
          <Typography component="h1" variant="h5">
            Change Password
          </Typography>
          <Box style={{ marginTop: 12 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body2" display="block" gutterBottom>
                  In order to <b>protect your account</b>, keep your password of
                  at least 10 characters.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  value={inputs.password}
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="password"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  value={inputs.confirmPassword}
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  id="confirmPassword"
                  autoComplete="password"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Button
              onClick={handleSubmit}
              fullWidth
              color="primary"
              variant="contained"
              style={{ marginTop: 18, marginBottom: 8 }}
              disabled={!validateForm()}
            >
              Continue
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to="/login">Already have an account? Sign In</Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      ) : (
        <ResetSuccess />
      )}

      {state.success && <CustomSuccessTemplate message={`Password Changed`} />}
      {state.error && <CustomErrorTemplate message={`Password Change Error`} />}
    </Container>
  );
};

export default ResetPassword;
