/* eslint-disable react-hooks/exhaustive-deps */

import {
  ChevronLeft,
  Image,
  Logout,
  MenuBook,
  Stars,
  SubdirectoryArrowRight,
} from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Box,
  Collapse,
  CssBaseline,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import React, { useEffect } from 'react';
import { Breadcrumbs, BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  Link as RouterLink,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import Explorer from './Components/API/Explorer';
import AddAruco from './Components/Aruco/AddAruco';
import Arucos from './Components/Aruco/Arucos';
import EditAruco from './Components/Aruco/EditAruco';
import AddBook from './Components/Books/AddBook';
import Books from './Components/Books/Books';
import EditBook from './Components/Books/EditBook';
import ViewBook from './Components/Books/ViewBook';
import AddRecoTest from './Components/RecoTests/AddRecoTest';
import RecoTests from './Components/RecoTests/RecoTests';
import Resources from './Components/Resources/Resources';
import AddSkill from './Components/Skills/AddSkill';
import Skills from './Components/Skills/Skills';
import AddTest from './Components/Tests/AddTest';
import Tests from './Components/Tests/Tests';
import ViewTest from './Components/Tests/ViewTest';
import Profile from './Components/User/Profile';
import ViewImage from './Components/ViewImage/ViewImage';
import CustomAvatar from './elements/CustomAvatar';
import { CustomDrawer, DrawerHeader } from './elements/CustomDrawer';
import { addBook } from '../redux/book/bookAction';
import { selectCurrentBook } from '../redux/book/bookSelector';
import { setCurrentUser } from '../redux/user/userAction';
import { selectCurrentUser } from '../redux/user/userSelector';

// const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(2),
  },
}));

const LinkRouter = (props) => (
  <Link
    {...props}
    color='link.primary'
    underline='hover'
    mx={1}
    component={RouterLink}
  />
);
const Dashboard = ({ setCurrentUser, currentUser, currentBook }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [book, setBook] = React.useState('#757575');
  const [resource, setResource] = React.useState('#757575');
  const [view, setView] = React.useState('#757575');
  const [skill, setSkill] = React.useState('#757575');
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname === '/home/books') {
      setBook('#f50057');
      setSkill('#757575');
      setResource('#757575');
    } else if (
      location.pathname === '/home/skills' ||
      location.pathname === '/home/skills/addSkill'
    ) {
      setBook('#757575');
      setSkill('#f50057');
      setResource('#757575');
    } else {
      setBook('#757575');
      setSkill('#757575');
      setResource('#f50057');
    }
    if (location.pathname === '/home/view') {
      setBook('#757575');
      setSkill('#757575');
      setResource('#757575');
      setView('#f50057');
    } else setView('#757575');
    if (
      currentBook &&
      location.pathname === `${match.path}/books/${currentBook._id}/editAruco`
    ) {
      setOpen(false);
    }
  }, []);

  const handleProfile = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    history.push({
      pathname: `/home/myProfile`,
      data: {
        user: user,
      },
    });
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    setCurrentUser(null);
    localStorage.clear();
    history.push({
      pathname: '/login',
    });
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <CustomDrawer
        variant='permanent'
        anchor='left'
        open={open}
        sx={{ display: 'flex', flexDirection: 'column' }}
      >
        <DrawerHeader>
          {!open && (
            <IconButton
              color='inherit'
              aria-label='open drawer'
              onClick={handleDrawerOpen}
              edge='start'
            >
              <MenuIcon />
            </IconButton>
          )}
          {open && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                flex: '1 1 auto',
                pr: 0,
              }}
            >
              <Typography
                variant='button'
                display='block'
                sx={{
                  flexShrink: 0,
                  textTransform: 'none',
                  px: 3,
                  justifyContent: 'center',
                }}
              >
                SmartPaper
              </Typography>
              <IconButton
                onClick={handleDrawerClose}
                sx={{ alignSelf: 'flex-end', ml: 'auto' }}
              >
                {theme.direction === 'ltr' && <ChevronLeft />}
              </IconButton>
            </div>
          )}
        </DrawerHeader>
        {/* <DrawerHeader>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}>
            <MenuIcon />
          </IconButton>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </DrawerHeader> */}
        <Divider />
        <List sx={{ marginTop: 0 }}>
          <ListItem
            component={RouterLink}
            to={`${match.url}`}
            disablePadding
            sx={{ display: 'block' }}
            onClick={() => {
              dispatch(addBook(null));
              console.log(currentBook);
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <MenuBook sx={{ color: book }} />
              </ListItemIcon>
              <ListItemText
                sx={{ color: book, opacity: open ? 1 : 0 }}
                primary={`Books`}
              >
                Books
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <Collapse in={open} timeout='auto' unmountOnExit>
            {currentBook && (
              <List component='div' disablePadding sx={{ display: 'block' }}>
                <ListItem
                  component={RouterLink}
                  className={classes.nested}
                  to={`${match.url}/books/${currentBook._id}`}
                  sx={{ padding: 0 }}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <SubdirectoryArrowRight
                        style={{
                          color: resource,
                          padding: 0,
                          marginLeft: '20px',
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={`${currentBook.bookName}`}
                      sx={{
                        color: resource,
                        opacity: open ? 1 : 0,
                        padding: 0,
                        whiteSpace: 'pre-line',
                        overflow: 'hidden',
                        // wordBreak: 'break-all',
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              </List>
            )}
          </Collapse>
          <ListItem
            component={RouterLink}
            to={`${match.url}/skills`}
            disablePadding
            sx={{ display: 'block' }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <Stars style={{ color: skill }} />
              </ListItemIcon>
              <ListItemText
                primary={`Skills`}
                sx={{ color: skill, opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            component={RouterLink}
            to={`${match.url}/view`}
            disablePadding
            sx={{ display: 'block' }}
            onClick={() => {
              // dispatch(addBook(null));
              console.log(currentBook);
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <Image sx={{ color: view }} />
              </ListItemIcon>
              <ListItemText
                sx={{ color: view, opacity: open ? 1 : 0 }}
                primary={`View Image`}
              >
                View Image
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
        <Divider sx={{ marginTop: 'auto' }} />
        <List>
          <ListItem
            onClick={handleProfile}
            // component={Link}
            // to={`${match.url}/skills`}
            disablePadding
            sx={{ display: 'block' }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <CustomAvatar
                user={currentUser}
                url='https://lh3.googleusercontent.com/ogw/ADGmqu8IRt2zAKQDEDvqL5Egm51VKCxJm2eb-N8YELr3=s192-c-mo'
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : 'auto',
                  justifyContent: 'center',
                  width: 24,
                  height: 24,
                }}
              />
              <ListItemText
                primary={`${currentUser.name}`}
                sx={{ opacity: open ? 1 : 0 }}
                primaryTypographyProps={{
                  fontSize: 14,
                  fontWeight: 'medium',
                  color: '#000',
                }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            sx={{ display: 'block' }}
            onClick={handleLogout}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title='Sign Out' placement='bottom'>
                  <Logout
                    sx={{
                      transform: 'rotate(180deg)',
                      width: 24,
                      height: 24,
                    }}
                  />
                </Tooltip>
              </ListItemIcon>
              <ListItemText
                primary='Sign Out'
                sx={{
                  opacity: open ? 1 : 0,
                }}
                primaryTypographyProps={{
                  fontSize: 14,
                  fontWeight: 'bold',
                  color: '#000',
                }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </CustomDrawer>
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          px: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          flex: '1 1 auto',
        }}
      >
        {/* note: Breadcrumbs will either be wrapped inside drawerHeader or before
        Switch */}
        <DrawerHeader style={{ marginRight: 'auto' }}>
          {/* <CustomBreadcrumbs /> */}
          <Breadcrumbs
            separator={<> &gt;</>}
            item={LinkRouter}
            finalItem={'b'}
            finalProps={{
              style: {
                alignSelf: 'flex-start',
                marginLeft: 8,
                color: 'text.primary',
              },
            }}
            // style={{ selfAlign: 'flex-start', marginRight: 'auto' }}
          />

          {location.pathname !== '/home/skills' &&
            location.pathname !== '/home/skills/addSkill' &&
            location.pathname !== '/home/myProfile' &&
            location.pathname !== '/home/view' && (
              <BreadcrumbsItem to='/home/books'>Books</BreadcrumbsItem>
            )}

          {currentBook &&
            location.pathname !== `/home/skills` &&
            location.pathname !== `/home/myProfile` &&
            location.pathname !== '/home/skills/addSkill' &&
            location.pathname !== '/home/view' && (
              <BreadcrumbsItem to={`/home/books/${currentBook._id}`}>
                {currentBook.bookName}
              </BreadcrumbsItem>
            )}
          {(location.pathname === '/home/skills' ||
            location.pathname === '/home/skills/addSkill') && (
            <BreadcrumbsItem to='/home/skills'>Skills</BreadcrumbsItem>
          )}
          {currentBook &&
            (location.pathname === `/home/books/${currentBook._id}/resources` ||
              location.pathname ===
                `/home/books/${currentBook._id}/editAruco`) && (
              <BreadcrumbsItem to={`/home/books/${currentBook._id}/resources`}>
                Smart Pages
              </BreadcrumbsItem>
            )}
          {location.pathname === `/home/view` && (
            <BreadcrumbsItem to={`/home/view`}>View Image</BreadcrumbsItem>
          )}
          {currentBook &&
            (location.pathname === `/home/books/${currentBook._id}/tests` ||
              location.pathname ===
                `/home/books/${currentBook._id}/tests/viewTest` ||
              location.pathname ===
                `/home/books/${currentBook._id}/tests/addTest`) && (
              <BreadcrumbsItem to={`/home/books/${currentBook._id}/tests`}>
                Tests
              </BreadcrumbsItem>
            )}
        </DrawerHeader>

        <Switch>
          <Route exact path={`/home`}>
            <Redirect to={`${match.path}/books`} push={true} />
          </Route>
          <Route exact path={`${match.path}/api`} component={Explorer} />
          <Route exact path={`${match.path}/books`} component={Books} />
          <Route
            exact
            path={`${match.path}/books/addBook`}
            component={AddBook}
          />
          <Route
            exact
            path={`${match.path}/books/:bookId/editBook`}
            component={EditBook}
          />
          <Route
            exact
            path={`${match.path}/books/:bookId`}
            component={ViewBook}
          />
          <Route
            exact
            path={`${match.path}/books/:bookId/resources`}
            component={Resources}
          />
          <Route
            exact
            path={`${match.path}/books/:bookId/arucos`}
            component={Arucos}
          />
          <Route
            exact
            path={`${match.path}/books/:bookId/arucos/addAruco`}
            component={AddAruco}
          />
          <Route
            exact
            path={`${match.path}/books/:bookId/tests`}
            component={Tests}
          />
          <Route
            exact
            path={`${match.path}/books/:bookId/tests/addTest`}
            component={AddTest}
          />
          <Route
            exact
            path={`${match.path}/books/:bookId/tests/viewTest`}
            component={ViewTest}
          />
          <Route exact path={`${match.path}/skills`} component={Skills} />
          <Route
            exact
            path={`${match.path}/skills/addSkill`}
            component={AddSkill}
          />
          <Route exact path={`${match.path}/view`} component={ViewImage} />
          <Route
            exact
            path={`${match.path}/books/:bookId/recoTests`}
            component={RecoTests}
          />
          <Route
            exact
            path={`${match.path}/books/:bookId/recoTests/addRecoTest`}
            component={AddRecoTest}
          />
          <Route
            exact
            path={`${match.path}/books/:bookId/editAruco`}
            component={EditAruco}
          />
          <Route exact path={`/home/myProfile`} component={Profile} />
        </Switch>
      </Box>
    </Box>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  currentBook: selectCurrentBook,
});
const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  addBook: (book) => dispatch(addBook(book)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
