/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Add, Assessment, MenuBook } from '@mui/icons-material';
import { ButtonBase, Grid, Paper, Typography } from '@mui/material';
import { styled, withStyles } from '@mui/styles';
import JsFileDownloader from 'js-file-downloader';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import useModal from '../Hooks/useModal';
import AddPage from '../../elements/AddPage';
import { CustomIconLabelButton } from '../../elements/CustomButton';
import { selectCurrentBook } from '../../../redux/book/bookSelector';
import { setCurrentUser } from '../../../redux/user/userAction';
import { selectCurrentUser } from '../../../redux/user/userSelector';

const styles = () => ({
  root: {
    minWidth: 275,
    flexGrow: 1,
    color: 'black',
    margin: 'auto',
  },
  title: {
    fontSize: 18,
    color: 'black',
  },
  pos: {
    marginBottom: 12,
  },
  content: {
    padding: 24,
  },
  link: {
    fontSize: '13px',
  },
  cta: {
    textTransform: 'initial',
  },
});

const options = [
  {
    title: 'Smart Pages',
    route: 'resources',
    details: 'Manage Smart Pages',
    icon: <MenuBook fontSize='medium' />,
  },
  {
    title: 'Assessments',
    route: 'tests',
    details: 'Manage Assessments',
    icon: <Assessment fontSize='medium' />,
  },
];

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
  cursor: 'auto',
  // borderRadius: '50%',
  // border: '1px solid #a6a4a4',
  // borderRadius: '8px',
});
const ViewBook = ({ currentUser, currentBook, ...props }) => {
  const history = useHistory();
  const [state, setState] = useState({
    author: [],
    token: '',
    book: [],
    downloadSuccess: false,
    downloadError: false,
  });
  const {
    isShowing,
    toggle,
    maxWidth,
    fullWidth,
    handleMaxWidthChange,
    handleFullWidthChange,
  } = useModal();
  useEffect(() => {
    document.title = 'Authoring Tool | ViewBook ';
    if (!('user' in localStorage || 'token' in localStorage)) {
      setCurrentUser(null);
      history.push({
        pathname: '/login',
      });
    }
    if (currentBook === undefined) {
      history.push({
        pathname: `/home/books`,
      });
    } else {
      setState((prevState) => ({
        ...prevState,
        author: currentUser,
        book: currentBook,
      }));
    }

    return () => {};
  }, []);

  const downloadProcessedBook = (e) => {
    new JsFileDownloader({
      url: state.book.processedBook,
      filename: `${state.book.bookName}.pdf`,
    })
      .then(() => {
        setState((prevState) => ({ ...prevState, downloadSuccess: true }));
      })
      .catch((error) => {
        console.error(error);
        setState((prevState) => ({ ...prevState, downloadError: true }));
      });
  };

  const handleRoute = (prop) => {
    const pathName = `/home/books/${state.book._id}`;
    history.push({
      pathname: `${pathName}/${prop}`,
      data: {
        book: state.book,
        author: state.author,
      },
    });
  };
  return (
    <>
      <Paper
        sx={{
          pl: 2,
          py: 2,
          pr: 0,
          mr: 0,
          margin: 'auto',
          minWidth: '500px',
          width: '100%',
          maxWidth: '100%',
          // textAlign: 'center',
          // maxWidth: 500,
          flexGrow: 1,
          backgroundColor: (theme) =>
            theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        }}
      >
        <Grid container>
          <Grid item>
            <ButtonBase sx={{ width: 128, height: 128 }}>
              <Img alt='complex' src={currentBook.thumbnail} />
            </ButtonBase>
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction='column' spacing={2}>
              <Grid item xs>
                <Typography gutterBottom variant='subtitle1' component='div'>
                  Book Name : {currentBook.bookName}
                </Typography>
                <Typography variant='body2' component='div' gutterBottom mt={3}>
                  Book Type : {currentBook.bookType}
                </Typography>
                <Typography variant='body2' gutterBottom>
                  Publisher :{' '}
                  <span
                    style={{ textDecoration: 'underline', display: 'inline' }}
                  >
                    {currentBook.publisher}
                  </span>
                </Typography>
                <Typography variant='body2'>
                  Pages : {currentBook.totalPages}
                </Typography>
              </Grid>
              {/* <Grid item>
                <Typography sx={{ cursor: 'pointer' }} variant='body2'>
                  Remove
                </Typography>
              </Grid> */}
            </Grid>
            <Grid item>
              <CustomIconLabelButton
                onClick={toggle}
                variant='contained'
                // style={{ background: '#06224A' }}
                // color='secondary'
              >
                <Add />
                <span style={{ marginTop: '8px' }}>Add Pages</span>
              </CustomIconLabelButton>
              {/* <CustomIconLabelButton
                onClick={(event) => downloadProcessedBook(event)}
                // variant='contained'
                // color='secondary'
                // style={{ background: '#06224A' }}
              >
                <GetApp />
                <span style={{ marginTop: '8px' }}>Download Book</span>
              </CustomIconLabelButton> */}
              {options.map(({ title, route, details, icon }, index) => (
                <CustomIconLabelButton
                  aria-label='Smart Pages'
                  id={`title-${index}`}
                  key={index}
                  onClick={() => handleRoute(route)}
                >
                  {icon}
                  <span style={{ marginTop: '8px' }}>{title}</span>
                </CustomIconLabelButton>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <AddPage
        isShowing={isShowing}
        hide={toggle}
        maxWidth={maxWidth}
        handleFullWidthChange={handleFullWidthChange}
        handleMaxWidthChange={handleMaxWidthChange}
        fullWidth={fullWidth}
        user={currentUser}
        book={currentBook}
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  currentBook: selectCurrentBook,
});

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(ViewBook)
);
