/* eslint-disable simple-import-sort/imports */
import React from 'react';
import { Switch } from 'react-router-dom';

import Explorer from '../Pages/Components/API/Explorer';
import EditAruco from '../Pages/Components/Aruco/EditAruco';
import ForgotPassword from '../Pages/Components/Auth/ForgotPassword';
import LoginPage from '../Pages/Components/Auth/LoginPage';
import Login from '../Pages/Components/Auth/LoginPage2';
import { RegisterPage } from '../Pages/Components/Auth/RegisterPage';
import AddBook from '../Pages/Components/Books/AddBook';
import Books from '../Pages/Components/Books/Books';
import EditBook from '../Pages/Components/Books/EditBook';
import PrivateRoute from '../Pages/Components/PrivateRoute';
import PublicRoute from '../Pages/Components/PublicRoute';
import AddRecoTest from '../Pages/Components/RecoTests/AddRecoTest';
import RecoTests from '../Pages/Components/RecoTests/RecoTests';
import Resources from '../Pages/Components/Resources/Resources';
import AddSkill from '../Pages/Components/Skills/AddSkill';
import Skills from '../Pages/Components/Skills/Skills';

import Profile from '../Pages/Components/User/Profile';
import ResetPassword from '../Pages/Components/User/ResetPassword';
import Signup from '../Pages/Components/User/Signup';
import LoadingButton from '../Pages/elements/LoadingButton';

import Dashboard from '../Pages/Dashboard';
import VerifyOtp from '../Pages/VerifyOtp';
import CustomError from '../Pages/elements/CustomError';
import { CustomDrawer } from '../Pages/elements/CustomDrawer';
import ViewImage from '../Pages/Components/ViewImage/ViewImage';

const Routes = () => {
  return (
    <Switch>
      <PublicRoute exact path='/' component={LoginPage} />
      <PublicRoute exact path='/login' component={LoginPage} />
      <PublicRoute path='/forgot-password' component={ForgotPassword} />
      <PublicRoute exact path='/login2' component={Login} />
      <PublicRoute path='/loadingButton' component={LoadingButton} />
      <PublicRoute path='/register' component={RegisterPage} />
      <PublicRoute path='/cd' component={CustomDrawer} />
      <PublicRoute
        path='/password-reset/:userId/:token'
        component={ResetPassword}
      />
      <PublicRoute exact path='/verifyOtp' component={VerifyOtp} />
      <PrivateRoute path='/home' component={Dashboard} />
      <PrivateRoute path='/books' component={Books} />
      <PrivateRoute path='/api' component={Explorer} />
      <PrivateRoute path='/addBook' component={AddBook} />
      <PrivateRoute path='/editBook' component={EditBook} />
      {/* <PrivateRoute path='/options' component={ViewBook} /> */}
      <PrivateRoute path='/recoTests' component={RecoTests} />
      <PrivateRoute path='/addRecoTest' component={AddRecoTest} />
      <PrivateRoute path='/resources' component={Resources} />
      <PrivateRoute path='/editAruco' component={EditAruco} />
      <PrivateRoute path='/myProfile' component={Profile} />
      <PublicRoute path='/signup' component={Signup} />
      <PrivateRoute path='/skills' component={Skills} />
      <PrivateRoute path='/addSkill' component={AddSkill} />
      <PrivateRoute path='/view' component={ViewImage} />
      {}
      <CustomError
        errorCode='404'
        errorMessage='Page Not Found!'
        errorRoute={`/`}
      />
    </Switch>
  );
};

export default Routes;
