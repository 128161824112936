import { Button } from '@mui/material';
import React, { useState } from 'react';
import { Circles } from 'react-loader-spinner';
const LoadingButton = ({
  onClick,
  loadingText,
  defaultText,
  style,
  timeout,
  loaderColor,
  loaderHeight,
  loaderWidth,
  color = 'primary',
  variant = 'contained',
}) => {
  const [state, setState] = useState({
    loading: false,
  });

  const handleClick = () => {
    setState({
      loading: true,
    });
    setTimeout(() => {
      setState({ loading: false });
    }, timeout);
  };
  const { loading } = state;
  return (
    <Button
      fullWidth
      color={color}
      variant={variant}
      style={style}
      onClick={onClick || handleClick}
      disabled={loading}>
      {loading && (
        <span style={{ padding: '0 10px 0 0 ' }}> {loadingText}</span>
      )}
      {!loading && <span>{defaultText}</span>}
      {loading && (
        <Circles
          style={{ paddingTop: '2px' }}
          color={loaderColor}
          height={loaderHeight}
          width={loaderWidth}
        />
      )}
    </Button>
  );
};

export default LoadingButton;
