/* eslint-disable unused-imports/no-unused-vars */
import { Add, Delete, Edit, Settings } from '@mui/icons-material';
import {
  Alert,
  Button,
  IconButton,
  Link,
  Snackbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { red } from '@mui/material/colors';
import { withStyles } from '@mui/styles';
import Axios from 'axios';
import MaterialTable from 'material-table';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import CustomSuccessTemplate from '../../elements/CustomSuccessTemplate';
import { addBook } from '../../../redux/book/bookAction';
import { selectCurrentUser } from '../../../redux/user/userSelector';
const styles = (theme) => ({
  root: {
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '0px',
    padding: '0px',
    width: '300',
    outline: 'none',
    fontSize: '0px',
    lineHeight: '0px',
    maxWidth: 335,
    // fontSize: 40,
    // height: 'auto'
  },
  media: {
    width: '100%',
    // height: 300,
    borderRadius: '4px',
    position: 'relative',
    zIndex: 1000,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  addBook: {
    width: 'auto',
    maxWidth: 325,
    height: 'auto',
    textAlign: 'center',
  },
  cardImage: {
    margin: '0px',
    padding: '0px',
    width: '100%',
    outline: 'none',
    // height:'auto'
  },
  cardHeader: {
    fontSize: '12px',
  },
  button: {
    // #005792
    background: '#06224A',
    borderRadius: '4px',
    color: '#FBFAF2',
    fontFamily: 'Rubik',
    marginRight: '20px',
    fontWeight: '500',
    textAlign: 'center',
    fontSize: 14,
    textTransform: 'none',
    '&:hover': {
      // background: '#B5CCEC',
      background: '#01204A',
    },
  },
});

const token = JSON.parse(localStorage.getItem('token'));
class Books extends Component {
  constructor(props) {
    super(props);

    this.state = {
      books: [],
      user: [],
      columns: [
        {
          title: 'BOOK NAME',
          field: 'bookName',
          editable: 'onUpdate',
          cellStyle: {
            textAlign: 'left',
            fontWeight: '500',
            fontSize: 16,
            color: '#01204A',
            borderColor: '#E6EEF8',
          },
          headerStyle: {},
          render: (rowData) => (
            <div>
              <div
                style={{
                  display: 'inline-block',
                  verticalAlign: 'top',
                  marginLeft: '10px',
                }}
              >
                <img
                  src={rowData.thumbnail}
                  alt={rowData.bookName}
                  style={{ width: 50, height: 60 }}
                />
              </div>
              <Link
                style={{
                  display: 'inline-block',
                  height: '60px',
                  lineHeight: '60px',
                  textAlign: 'center',
                  margin: '4px auto auto 16px',
                  cursor: 'pointer',
                  color: '#01204A',
                  // color: '#0a1675',
                }}
                rel='noopener noreferrer'
                underline='hover'
                onClick={(e) => this.viewBook(e, rowData)}
              >
                {rowData.bookName}
              </Link>
            </div>
          ),
        },
        /* {
                    title: 'STATUS', field: 'status', editable: 'never',
                    width: 'auto',
                    render: rowData =>
                        <div>
                            {
                                (rowData.status === 'Processed') ?
                                    (
                                        <div className="processed">
                                            {rowData.status}
                                        </div>
                                    ) :
                                    (
                                        <div className="processing">
                                            {rowData.pagesProcessed} / {rowData.totalPages}
                                        </div>
                                    )
                            }
                        </div>

                }, */
        /* {
                    title: 'DATE UPLOADED', field: 'createdOn', editable: 'never',
                    width: 'auto',
                    render: rowData => {
                        const loc = new Date(rowData.createdOn);
                        const finDate = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'short', day: '2-digit' }).format(loc);
                        return finDate;
                    }
                }, */
        /* {
                    title: 'TOTAL SKILLS', field: 'totalSkills', editable: 'never',
                    width: 'auto'
                }, */
        {
          title: 'ACTION',
          editable: 'never',
          width: 'auto',
          cellStyle: {
            textAlign: 'center',
          },
          render: (rowData) => (
            <>
              <Tooltip title='Edit Book'>
                <IconButton
                  aria-label='Edit Book'
                  style={{
                    background: 'none',
                    color: '#A4A9AF',
                    marginRight: '6px',
                  }}
                  size='small'
                  onClick={(e) => {
                    this.viewBook(e, rowData);
                  }}
                >
                  <Edit style={{ fontSize: '22px' }} />
                </IconButton>
              </Tooltip>
              <Tooltip title='Book Settings'>
                <IconButton
                  aria-label='Book Settings'
                  style={{
                    background: 'none',
                    color: '#A4A9AF',
                    marginRight: '6px',
                  }}
                  size='small'
                  // disabled={rowData.status === 'Processing'}
                  onClick={() => {
                    this.editBook(rowData);
                  }}
                >
                  <Settings style={{ fontSize: '22px' }} />
                </IconButton>
              </Tooltip>
              <Tooltip title='Delete Book'>
                <IconButton
                  aria-label='Delete Book'
                  // disabled={rowData.status === 'Processing'}
                  style={{
                    background: 'none',
                    color: '#A4A9AF',
                    marginRight: '6px',
                  }}
                  size='small'
                  onClick={() => {
                    this.deleteBook(rowData);
                  }}
                >
                  <Delete style={{ fontSize: '22px' }} />
                </IconButton>
              </Tooltip>
            </>
          ),
        },
      ],
      data: [],

      options: {
        paging: true,
        pageSize: 5,
        search: true,
        selection: false,
        searchFieldAlignment: 'left',
        searchFieldStyle: {
          backgroundColor: '#FFFFFF',
          // border: '1px solid #B5CCEC',
          borderRadius: '2px',
          fontFamily: 'Rubik',
          fontWight: '400',
          fontSize: '14px',
          color: '#A4A9AF',
          letterSpacing: '0px',
        },
        actionsColumnIndex: -1,

        headerStyle: {
          textAlign: 'center',
          fontWeight: '500',
          fontSize: 12,
          color: '#707A85',
          borderTop: '1px solid',
          borderColor: '#E6EEF8',
          // paddingLeft: 0,
          // marginLeft: 0
        },
        cellStyle: {
          textAlign: 'center',
          fontWeight: '400',
          fontSize: 16,
          color: '#01204A',
          borderColor: '#E6EEF8',
        },
        actionsCellStyle: {
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        },
        rowStyle: {
          textAlign: 'center',
          padding: 'auto',
        },
      },
      bookError: false,
      tableMessage: 'Searching for books...',
    };
  }

  viewBook = (event, book) => {
    event.preventDefault();
    const { addBook } = this.props;
    addBook(book);
    this.props.history.push({
      pathname: `/home/books/${book._id}`,
    });
  };

  // 'https://prod.paperflowapp.com/authoring-util/book/delete'
  deleteBook = (book) => {
    const bookName = book.bookName;
    const enteredName = window.prompt(`Please type ${bookName} to confirm.`);
    if (enteredName === book.bookName) {
      Axios.post(
        `${process.env.REACT_APP_API_URL}/book/remove`,
        {
          bookId: book._id,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
        .then(async () => {
          this.setState((prevState) => ({
            ...prevState,
            bookDeleteSuccess: true,
          }));
          this.getBooks();
        })
        .catch(() => {});
    } else {
      // window.alert('Could not delete book try again!');
      this.setState({ bookError: true });
    }
  };

  componentDidMount() {
    const { currentUser, token: authToken } = this.props;
    this.props.addBook(null);
    this.setState({ user: currentUser }, () =>
      this.getBooks(currentUser.token || authToken || token)
    );
  }

  // https: //prod.paperflowapp.com/authoring-book/author/book/get
  getBooks = async (token) => {
    const { _id } = this.state.user;
    await Axios.post(
      `${process.env.REACT_APP_API_URL}/book/get`,
      {
        authorId: _id,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((response) => {
        const { books } = response.data;
        if (books.length > 0) {
          this.setState({ data: books.reverse() });
        }
        this.setState({
          tableMessage: 'No books found, please add a new book!',
        });
      })
      .catch(() => {});
  };

  addNewBook = () => {
    const { pathname } = this.props.location;
    this.props.history.push({
      pathname: `${pathname}/addBook`,
      data: this.state.teacher,
    });
  };

  editBook = (book) => {
    const { pathname } = this.props.location;
    this.props.addBook(book);
    this.props.history.push({
      pathname: `${pathname}/${book._id}/editBook`,
      data: book,
    });
  };

  render() {
    const { classes } = this.props;

    const handleError = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }

      this.setState({ bookError: false });
    };
    return (
      <>
        <MaterialTable
          style={{
            minWidth: '500px',
            width: '100%',
            maxWidth: '100%',
            textAlign: 'center',
          }}
          localization={{
            body: {
              emptyDataSourceMessage: `${this.state.tableMessage}`,
              editRow: { deleteText: 'Are you sure you want to delete?' },
              fontSize: '14px',
            },
            toolbar: { searchPlaceholder: 'Search Books' },
          }}
          options={this.state.options}
          title=''
          columns={this.state.columns}
          data={this.state.data}
          actions={this.state.actions}
          components={{
            Actions: (props) => (
              <Tooltip title='Add New Book'>
                <Button
                  onClick={(event) => this.addNewBook(event)}
                  variant='contained'
                  color='secondary'
                  className={classes.button}
                  startIcon={<Add />}
                  size='small'
                >
                  Add Book
                </Button>
              </Tooltip>
            ),
          }}
        />

        {this.state.bookDeleteSuccess && (
          <CustomSuccessTemplate message='Book deleted succesfully!' />
        )}

        {this.state.bookError && (
          <Snackbar
            open={this.state.bookError}
            autoHideDuration={4000}
            onClose={handleError}
          >
            <Alert
              variant='filled'
              elevation={6}
              onClose={handleError}
              severity='error'
            >
              <Typography className='bookDeleteError'>
                Could not delete book, Try again!
              </Typography>
            </Alert>
          </Snackbar>
        )}
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  addBook: (book) => dispatch(addBook(book)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Books));
