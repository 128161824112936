/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable unused-imports/no-unused-vars */
import { Avatar, Button, Grid, Paper, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";

import CustomErrorTemplate from "../../elements/CustomErrorTemplate";
import CustomSuccessTemplate from "../../elements/CustomSuccessTemplate";
import profilePic from "../../../Assets/profile.png";
import { setCurrentUser } from "../../../redux/user/userAction";
import { selectCurrentUser } from "../../../redux/user/userSelector";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    margin: "auto",
    minWidth: 500,
    width: "auto",
    maxWidth: "50%",
    borderRadius: "10px",
    flexGrow: 1,
  },
}));

const token = JSON.parse(localStorage.getItem("token"));
const Profile = ({ currentUser, ...props }) => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    name: "",
    email: "",
    organization: "",
    changedFields: [],
    user: null,
    userId: "",
    updateError: false,
    updateSuccess: false,
    updateErrorMessage: "",
    updateSuccessMessage: "",
    reload: false,
  });

  useEffect(() => {
    // getUserDetails();
    if (currentUser === null || currentUser === undefined) {
      props.history.push({
        pathname: "/login",
      });
    } else {
      setState((prevState) => ({
        ...prevState,
        user: currentUser,
        userId: currentUser._id,
        name: currentUser.name,
        email: currentUser.email,
        organization: currentUser.organization,
        changedFields: [],
      }));
    }
    return () => {};
  }, []);

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const updateFields = (e) => {
    if (
      e.target.value !== state.user[e.target.name] &&
      state.changedFields.indexOf(e.target.name) === -1
    ) {
      setState((prevState) => ({
        ...prevState,
        changedFields: [...state.changedFields, e.target.name],
      }));
    } else if (e.target.value === state.user[e.target.name]) {
      setState((prevState) => ({
        ...prevState,
        changedFields: state.changedFields.filter(
          (field) => field !== e.target.name
        ),
      }));
    }
  };

  const updateProfile = async () => {
    let updateObj = {};
    for (const field of state.changedFields) {
      updateObj[field] = state[field];
    }
    // 'https://prod.paperflowapp.com/user/updateProfile'

    await Axios.post(
      `${process.env.REACT_APP_AUTH_API_URL}/user/update`,
      {
        userId: state.user._id,
        ...updateObj,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((res) => {
        const { user } = res.data;
        const updatedUser = {
          ...user,
          token: token,
        };
        localStorage.setItem("user", JSON.stringify(updatedUser));
        dispatch(setCurrentUser(user));
        setState((prevState) => ({
          ...prevState,
          user: user,
          name: user.name,
          email: user.email,
          organization: user.organization,
          userId: user._id,
          changedFields: [],
          updateSuccess: true,
          updateError: false,
          updateSuccessMessage: "Profile updated successfully",
        }));
        setTimeout(() => {
          setState((prevState) => ({
            ...prevState,
            updateSuccess: false,
            updateSuccessMessage: "",
          }));
        }, 4000);
      })
      .catch((err) => {
        console.log("err", err);
        setState((prevState) => ({
          ...prevState,
          updateError: true,
          updateErrorMessage: err.response.data.message.message,
          name: state.user.name,
          organization: state.user.organization,
        }));
        setTimeout(() => {
          setState((prevState) => ({
            ...prevState,
            updateError: false,
            updateErrorMessage: "",
          }));
        }, 4000);
      });
  };
  // 'https://dev.smartpaperapp.com/smart-user/get'
  const getUserDetails = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    await Axios.post(
      `${process.env.REACT_APP_AUTH_API_URL}/user/get`,
      {
        userId: user._id,
        userType: "author",
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((response) => {
        console.log(response.data);
        const { name, email, organization, _id } = response.data.user;
        setState((prevState) => ({
          ...prevState,
          name: name,
          email: email,
          organization: organization,
          userId: _id,
          user: response.data.user,
          changedFields: [],
        }));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const classes = useStyles();
  return (
    <>
      <BreadcrumbsItem to="/home/myProfile">My Profile</BreadcrumbsItem>
      <Paper className={classes.paper} variant="elevation" elevation={6} square>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs>
            <Avatar
              alt={state.name}
              src={profilePic}
              sx={{ width: 64, height: 64 }}
            />
          </Grid>
          <Grid item xs>
            <TextField
              style={{ width: "300px" }}
              margin="normal"
              size="medium"
              type="text"
              id="name"
              name="name"
              value={state.name}
              label="Name"
              onBlur={updateFields}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs>
            <TextField
              style={{ width: "300px" }}
              margin="normal"
              size="medium"
              type="text"
              id="organization"
              name="organization"
              value={state.organization}
              label="Organization"
              onBlur={updateFields}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs>
            <TextField
              style={{ width: "300px" }}
              margin="normal"
              size="medium"
              type="email"
              id="email"
              name="email"
              value={state.email}
              label="e-mail"
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs>
            <Button
              disabled={!state.changedFields.length}
              width="auto"
              variant="contained"
              color="primary"
              className="submitButton"
              onClick={() => updateProfile()}
            >
              Update Profile
            </Button>
          </Grid>
        </Grid>
      </Paper>
      {state.updateError && (
        <CustomErrorTemplate message={state.updateErrorMessage} />
      )}
      {state.updateSuccess && (
        <CustomSuccessTemplate message={state.updateSuccessMessage} />
      )}
    </>
  );
};
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(Profile);
