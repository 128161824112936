/* eslint-disable unused-imports/no-unused-vars */
import Axios from 'axios';

export const userService = {
  login,
  logout,
  register,
  forgot,
  changepassword,
};
const token = JSON.parse(localStorage.getItem('token'));
function login(email, password) {
  const requestOptions = {
    userType: 'author',
    email: email,
    password: password,
  };
  // return Axios.post(`http://localhost:4000/dev/auth/login`, requestOptions).then(handleResponse).then((user)=> {

  return Axios.post(`http://localhost:4000/dev/auth/login`, requestOptions)
    .then((res) => handleResponse(res))
    .catch((e) => handleResponse(e));
  /* .then((user) => {
      localStorage.setItem("user", JSON.stringify(user));
      return user;
    }) */
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
}

function register(user) {
  const requestOptions = {
    userType: user.userType,
    organization: user.organization,
    name: `${user.firstName} ${user.lastName}`,
    email: user.email,
    password: user.password,
  };
  return Axios.post(`http://localhost:4000/dev/auth/signUp`, requestOptions)
    .then((res) => handleResponse(res))
    .then((user) => {
      console.log(user);
    })
    .catch((e) => {
      throw e;
    });
}

function forgot(user) {
  const requestOptions = {
    email: user.email,
  };
  return Axios.post(
    `http://localhost:4000/dev/auth/generateResetLink`,
    requestOptions
  )
    .then(handleResponse)
    .then((user) => {
      console.log(user);
    })
    .catch((e) => {
      throw e;
    });
}

function changepassword(data) {
  // const requestOptions = {
  //     email: data.email
  // };
  console.log(data);
  return false;
  // return Axios.post(`http://localhost:4000/dev/auth/generateResetLink`, requestOptions).then(handleResponse).then((user)=> {

  //   }).catch((e) => {
  //     throw e;
  //   });
}

function handleResponse(response) {
  if (response.status === 200) {
    return response.data;
  } else {
    // logout();
  }
  /* return response.then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        // location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  }); */
}

export const getBookName = async (bookId) => {
  const res = await Axios.post(
    'https://prod.paperflowapp.com/authoring-no-aruco-book/book/getBookName',
    {
      bookId: bookId,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return res;
};
