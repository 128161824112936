import { Alert, Button, Snackbar } from '@mui/material';
import { withStyles } from '@mui/styles';
import Axios from 'axios';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import CustomLoader from './elements/CustomLoader';
import { history } from '..';
import { setCurrentUser } from '../redux/user/userAction';
import { selectCurrentUser } from '../redux/user/userSelector';

const styles = () => ({
  button: {
    fontSize: 14,
    textTransform: 'none',
    '&:disabled': {
      backgroundColor: 'grey',
    },
  },
});
class VerifyOtp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mobile: '',
      otp: '',
      type: '',
      isLoading: false,
      isLoggedIn: false,
      success: false,
      error: false,
    };
  }

  componentDidMount() {
    if (typeof this.props.location.data === 'undefined') {
      history.push('/');
    } else {
      const nData = this.props.location.data;
      this.setState({ mobile: nData.mobile, type: nData.type });
    }
  }
  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true, isLoggedIn: false });
    const { setCurrentUser } = this.props;

    // http://localhost:4000/author/loginWithOtp
    await Axios.post(
      'https://prod.paperflowapp.com/author-auth/author/loginWithOtp',
      {
        mobile: this.state.mobile,
        otp: parseInt(this.state.otp),
      }
    )
      .then((response) => {
        this.setState({ isLoading: false, success: true });

        setTimeout(() => {
          let user = response.data.author;
          user['token'] = response.data.token;
          localStorage.setItem('user', JSON.stringify(user));
          localStorage.setItem('token', JSON.stringify(response.data.token));
          setCurrentUser(user);
          this.setState({ isLoggedIn: true });
          this.props.history.push({
            pathname: '/home',
          });
        }, 5000);
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoading: false, error: true });
      });
  };
  render() {
    function getCodeBoxElement(index) {
      return document.getElementById('codeBox' + index);
    }
    const { classes } = this.props;
    function onKeyUpEvent(index, event) {
      const eventCode = event.which || event.keyCode;
      if (getCodeBoxElement(index).value.length === 1) {
        if (index !== 6) {
          getCodeBoxElement(index + 1).focus();
        } else {
          getCodeBoxElement(index).blur();
          // Submit code
          submitOtp();
        }
      }
      if (eventCode === 8 && index !== 1) {
        getCodeBoxElement(index - 1).focus();
      }
    }

    const validateForm = () => {
      return this.state.otp.length > 0;
    };
    const submitOtp = () => {
      let otpValue = '';
      for (let index = 1; index <= 6; index++) {
        const singleValue = getCodeBoxElement(index).value;
        otpValue = otpValue.concat(singleValue);
      }
      this.setState({ otp: otpValue });
      return otpValue;
    };
    const handleSuccess = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }

      this.setState({ success: false });
    };
    const handleError = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }

      this.setState({ error: false });
    };

    return (
      <div className='verifyOtp'>
        <section className='verifyOtp-container'>
          <form>
            <input
              id='codeBox1'
              type='number'
              maxLength='1'
              onKeyUp={(event) => onKeyUpEvent(1, event)}
            />
            <input
              id='codeBox2'
              type='number'
              maxLength='1'
              onKeyUp={(event) => onKeyUpEvent(2, event)}
            />
            <input
              id='codeBox3'
              type='number'
              maxLength='1'
              onKeyUp={(event) => onKeyUpEvent(3, event)}
            />
            <input
              id='codeBox4'
              type='number'
              maxLength='1'
              onKeyUp={(event) => onKeyUpEvent(4, event)}
            />
            <input
              id='codeBox5'
              type='number'
              maxLength='1'
              onKeyUp={(event) => onKeyUpEvent(5, event)}
            />
            <input
              id='codeBox6'
              type='number'
              maxLength='1'
              onKeyUp={(event) => onKeyUpEvent(6, event)}
            />
          </form>
          <div style={{ marginTop: '30px' }}>
            <Button
              disabled={!validateForm()}
              variant='contained'
              color='default'
              className={classes.button}
              onClick={this.handleSubmit}>
              Submit
            </Button>
          </div>
        </section>

        {this.state.isLoading ? (
          <div className='loading-overlay'>
            <CustomLoader />
          </div>
        ) : (
          <div></div>
        )}
        {this.state.success ? (
          <div>
            <Snackbar
              open={this.state.success}
              autoHideDuration={3000}
              onClose={handleSuccess}>
              <Alert
                onClose={handleSuccess}
                variant='filled'
                elevation={6}
                severity='success'>
                Yay! You are logged In!
              </Alert>
            </Snackbar>
          </div>
        ) : (
          <div></div>
        )}

        {this.state.error ? (
          <div>
            <Snackbar
              open={this.state.error}
              autoHideDuration={3000}
              onClose={handleError}>
              <Alert
                variant='filled'
                elevation={6}
                onClose={handleError}
                severity='error'>
                Incorrect OTP! Please Try again!
              </Alert>
            </Snackbar>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(VerifyOtp));
