/* eslint-disable unused-imports/no-unused-vars */
import { Alert, AlertTitle, Button, Snackbar, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Axios from 'axios';
import MaterialTable from 'material-table';
import React, { Component } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectCurrentBook } from '../../../redux/book/bookSelector';
import { selectCurrentUser } from '../../../redux/user/userSelector';

class ViewTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      book: [],
      infoColumns: [
        {
          title: '#',
          field: 'quesNum',
          editable: 'never',
          cellStyle: {
            textAlign: 'center',
            fontWeight: '500',
            fontSize: 16,
            color: '#01204A',
            borderColor: '#E6EEF8',
          },
          headerStyle: {
            textAlign: 'left',
          },
        },
        {
          title: 'CONTENT TYPE',
          field: 'contentType',
        },
        { title: 'CONTENT SUB-TYPE', field: 'contentSubType' },
        { title: 'PAGE ID', field: 'pageId' },
      ],
      columns: [
        {
          title: '#',
          field: 'quesNum',
          editable: 'never',
          cellStyle: {
            textAlign: 'left',
            fontWeight: '500',
            fontSize: 16,
            color: '#01204A',
            borderColor: '#E6EEF8',
          },
          headerStyle: {
            textAlign: 'left',
          },
        },
        {
          title: 'Content Subtype',
          field: 'contentSubType',
          cellStyle: {
            textAlign: 'center',
            padding: 0,
            paddingRight: '20px',
          },
          headerStyle: {
            textAlign: 'center',
          },
        },
        {
          title: 'ANSWER',
          field: 'answer',
          cellStyle: {
            textAlign: 'center',
            padding: 0,
            paddingRight: '20px',
          },
          headerStyle: {
            textAlign: 'center',
          },
        },
        {
          title: 'SKILL',
          field: 'skillName',
          editable: 'never',
          cellStyle: {
            textAlign: 'center',
            padding: 0,
            paddingRight: '20px',
          },
          headerStyle: {
            textAlign: 'center',
          },
        },
        {
          title: 'PAGE ID',
          field: 'pageId',
          cellStyle: {
            textAlign: 'center',
            padding: 0,
            paddingRight: '20px',
          },
          headerStyle: {
            textAlign: 'center',
          },
        },
        {
          title: 'MAX SCORE',
          field: 'maxScore',
          cellStyle: {
            textAlign: 'center',
            padding: 0,
            paddingRight: '20px',
          },
          headerStyle: {
            textAlign: 'center',
          },
        },
        /* {
          title: "Difficulty",
          field: "difficulty",
          editable: "never",
        },
        {
          title: "Explanation",
          field: "explanation",
        },
        {
          title: "Page ID",
          field: "pageId",
          editable: "never",
        }, */
      ],
      isProcessed: false,
      data: [],
      infoData: [],
      test: [],
      tableTitle: '',
      arucos: [],
      skills: [],
      totalQuestions: 0,
      arucoDetails: [],
      pages: [],
      pageIds: [],
      type: '',
      pageInfoSize: { pageSize: 3 },

      pageDataSize: { pageSize: 15 },
      options: {
        paging: true,
        // pageSize: 15,
        pageSizeOptions: [5, 10, 15, 20],
        search: false,
        selection: false,
        searchFieldAlignment: 'left',
        searchFieldStyle: {
          backgroundColor: '#FFFFFF',
          // border: '1px solid #B5CCEC',
          borderRadius: '2px',
          fontFamily: 'Rubik',
          fontWeight: '400',
          fontSize: '14px',
          color: '#A4A9AF',
          letterSpacing: '0px',
        },
        actionsColumnIndex: -1,

        headerStyle: {
          textAlign: 'center',
          fontWeight: '500',
          fontSize: 12,
          color: '#707A85',
          borderTop: '1px solid',
          borderColor: '#E6EEF8',
          textTransform: 'uppercase',
          // paddingLeft: 0,
          // marginLeft: 0
        },
        cellStyle: {
          textAlign: 'center',
          fontWeight: '400',
          fontSize: 16,
          color: '#01204A',
          borderColor: '#E6EEF8',
        },
        actionsCellStyle: {
          // display: "flex",
          justifyContent: 'center',
          borderBottom: '1px solid #E6EEF8',
          color: '#A4A9AF',
          fontSize: '22px',
          fontWeight: 'bold',
          texTransform: 'uppercase',
        },
        rowStyle: {
          textAlign: 'center',
          padding: 'auto',
        },
      },
      error: false,
      errorMessage: '',
      errorTitle: '',
      success: false,
      successMessage: '',
      successTitle: '',
      author: [],
      disabled: false,
    };
  }
  //  'https://prod.paperflowapp.com/author-skills/getSkills'
  componentDidMount = async () => {
    const token = JSON.parse(localStorage.getItem('token'));
    if (typeof this.props.location.data === 'undefined') {
      this.props.history.push({
        pathname: `/home/books`,
      });
    } else {
      if (this.props.location.data.type === 'create') {
        const { author } = this.props.location.data;
        // console.log('locaiton data', this.props.location.data);
        await Axios.post(
          `${process.env.REACT_APP_API_URL}/skills/get`,
          {
            userId: author._id,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
          .then((response) => {
            this.setState({ skills: response.data.skills });
          })
          .catch((error) => {});

        const { book, test, pages, pageIds, type } = this.props.location.data;
        // console.log('type', type, test);
        this.setState(
          {
            book: book,
            test: test,
            pages: pages,
            pageIds: pageIds,
            tableTitle: `${test.testName}`,
            type: type,
            author: author,
          },
          () => this.getPageDetails(pageIds, token)
        );

        //TODO Change Skill name with skill id using below code:

        // const skillid = this.state.data.find(skill => skill.id === item.skillname);
      } else {
        var tData = this.props.location.data.test;
        this.setState({ test: tData, tableTitle: `${tData.testName}` }, () =>
          this.getQuestions(token)
        );
      }
    }
  };
  // "https://prod.paperflowapp.com/authoring-page-metadata/pagemetadata/getPageMetadataDetails"
  getPageDetails = async (pageIds, token) => {
    await Axios.post(
      `${process.env.REACT_APP_API_URL}/pageMetadata/getDetails`,
      {
        pageIds: pageIds,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((response) => {
        this.setState({ pageDetails: response.data.pageDetails });
        let questionsData = [];
        let infoData = [];
        response.data.pageDetails.forEach((page, index) => {
          page.data.forEach(async (item, index) => {
            let skillDetails = this.state.skills.find(
              (sk) => sk.skillName === item.skillName
            );
            let result = {};
            result['quesNum'] = item.item;
            result['answer'] = `${item.ans}`;
            result['maxScore'] = item.maxScore;
            result['contentType'] = item.contentType;
            result['contentSubType'] = item.contentSubType;
            result['difficulty'] = item.difficulty;
            result['skillName'] = item?.skillName;
            result['skillId'] = skillDetails?.skillId;
            result['explanation'] = '';
            result['modelType'] = item.modelType;
            result['pageId'] = page.pageId;
            /* let pageNumber = await this.state.bookPageDetails.find(ar => ar.arucoId === aruco.arucoId);
                                    result[""] */
            if (item.skillName === undefined) {
              infoData.push(result);
            } else questionsData.push(result);
          });
        });

        // console.log(closest);
        // this.state.pageDataSize.pageSize = closest;
        // console.log(this.state.pageDataSize);
        this.setState({
          data: questionsData,
          totalQuestions: questionsData.length,
          pageDataSize: { pageSize: this.closestValueinArray(questionsData) },
          isProcessed: true,
          // infoData: infoData,
        });
      })
      .catch((error) => {});
  };
  // "https://prod.paperflowapp.com/author-test/getQuestions"
  getQuestions = async (token) => {
    await Axios.post(
      `${process.env.REACT_APP_API_URL}/test/questions/get`,
      {
        testId: this.state.test._id,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((response) => {
        this.setState({
          data: response.data.questions,
          pageDataSize: {
            pageSize: this.closestValueinArray(response.data.questions),
          },
          isProcessed: true,
        });
        // console.log('response ques', response.data.questions);
      })
      .catch((error) => {});
  };

  closestValueinArray = (questionsData) => {
    const counts = this.state.options.pageSizeOptions;
    const closest = counts.reduce(function (prev, curr) {
      return Math.abs(curr - questionsData.length) <
        Math.abs(prev - questionsData.length)
        ? curr
        : prev;
    });
    console.log(closest);
    return closest;
  };

  handleSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ success: false, successMessage: '', successTitle: '' });
  };
  handleError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ error: false, errorMessage: '', errorTitle: '' });
  };

  itemSubmit = async () => {
    // eslint-disable-next-line array-callback-return
    const token = JSON.parse(localStorage.getItem('token'));
    if (this.props.location.data.type === 'create') {
      const { data, test, book, pageIds, totalQuestions } = this.state;
      // const tempData = [...data];
      const submitData = data.map((ele) => {
        delete ele.tableData;
        return ele;
      });

      //http://localhost:4000/addTest
      await Axios.post(
        `${process.env.REACT_APP_API_URL}/test/add`,
        {
          items: submitData,
          test: test,
          book: book,
          pageId: pageIds,
          totalQuestions: totalQuestions,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
        .then((response) => {
          setTimeout(() => {
            /* this.setState((prevState) => ({
                ...prevState,
                success: false,
                successMessage: "",
                successTitle: "",
              })); */
            this.props.history.push({
              pathname: `/home/books/tests`,
              data: {
                book: this.state.book,
                author: this.state.author,
              },
            });
          }, 10);
        })
        .catch((error) => {
          /* this.setState((prevState) => ({
              ...prevState,
              error: true,
              errorMessage: "Could not create assessment, please try again",
              errorTitle: "Assessment Create Error",
            })); */
          setTimeout(() => {
            /* this.setState((prevState) => ({
                ...prevState,
                error: false,
                errorMessage: "",
                errorTitle: "",
              })); */
            this.props.history.push({
              pathname: `/home/books/tests`,
              data: {
                book: this.state.book,
                author: this.state.author,
              },
            });
          }, 1500);
        });
    }
  };
  render() {
    /* const itemView = () => {
                this.state.data.map((element) => {
                    let skillO = this.state.skills.find(o => o.skillname = element.skillname);
                    element["skillid"] = skillO.skillid;
                    return 0;
                });
            } */
    // "https://prod.paperflowapp.com/author-test/addTest"
    //todo: Submit item with pageIds and change backend accordingly
    // const itemSubmit = async () => {
    //   // eslint-disable-next-line array-callback-return
    //   const token = JSON.parse(localStorage.getItem('token'));
    //   if (this.props.location.data.type === 'create') {
    //     const { data, test, book, pageIds, totalQuestions } = this.state;
    //     // const tempData = [...infoData, ...data];
    //     const submitData = data.map((ele) => {
    //       delete ele.tableData;
    //       return ele;
    //     });
    //     //http://localhost:4000/addTest
    //     await Axios.post(
    //       `${process.env.REACT_APP_API_URL}/test/add`,
    //       {
    //         items: submitData,
    //         test: test,
    //         book: book,
    //         pageId: pageIds,
    //         totalQuestions: totalQuestions,
    //       },
    //       {
    //         headers: {
    //           Authorization: token,
    //         },
    //       }
    //     )
    //       .then((response) => {
    //         setTimeout(() => {
    //           /* this.setState((prevState) => ({
    //             ...prevState,
    //             success: false,
    //             successMessage: "",
    //             successTitle: "",
    //           })); */
    //           this.props.history.push({
    //             pathname: `/home/books/tests`,
    //             data: {
    //               book: this.state.book,
    //               author: this.state.author,
    //             },
    //           });
    //         }, 1500);
    //       })
    //       .catch((error) => {
    //         /* this.setState((prevState) => ({
    //           ...prevState,
    //           error: true,
    //           errorMessage: "Could not create assessment, please try again",
    //           errorTitle: "Assessment Create Error",
    //         })); */
    //         setTimeout(() => {
    //           /* this.setState((prevState) => ({
    //             ...prevState,
    //             error: false,
    //             errorMessage: "",
    //             errorTitle: "",
    //           })); */
    //           this.props.history.push({
    //             pathname: `/home/books/tests`,
    //             data: {
    //               book: this.state.book,
    //               author: this.state.author,
    //             },
    //           });
    //         }, 1500);
    //       });
    //   }
    // };
    const validateItemData = () => {
      return this.state.data.length !== 0;
    };
    return (
      <>
        {this.props.currentBook && (
          <BreadcrumbsItem
            to={`/home/books/${this.props.currentBook._id}/tests/viewTest`}
          >
            {this.state.test.testName}
          </BreadcrumbsItem>
        )}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            gap: '20px',
            mb: 1,
          }}
        >
          {/* <Button onClick={() => console.log('log', this.state)}>log</Button> */}
          <Typography>Type: {this.state.test.testType}</Typography>
          <Typography>
            Precision Mode: {this.state.test.precisionMode ? 'On' : 'Off'}
          </Typography>
          <Typography>
            Data Endpoint:{' '}
            {this.state.test.dataEndpoint
              ? this.state.test.dataEndpoint
              : 'not found'}
          </Typography>
          <Typography>
            Data Export Format: {this.state.test.dataExportFormat}
          </Typography>
        </Box>
        {this.state.isProcessed && (
          <MaterialTable
            style={{
              minWidth: '500px',
              width: '100%',
              maxWidth: '100%',
              textAlign: 'center',
            }}
            localization={{
              body: {
                emptyDataSourceMessage: 'Loading questions. . .',
                editRow: { deleteText: 'Are you sure you want to delete?' },
                fontSize: '14px',
              },
            }}
            options={{
              ...this.state.options,
              ...this.state.pageDataSize,
            }}
            title={
              <Typography
                variant='body1'
                style={{ color: '#000', fontWeight: '400', fontSize: '16px' }}
              >
                {this.state.tableTitle}
              </Typography>
            }
            actions={this.state.actions}
            columns={this.state.columns}
            data={this.state.data}
            // editable={{
            //   onRowUpdate: (newData, oldData) =>
            //     new Promise((resolve) => {
            //       setTimeout(() => {
            //         resolve();
            //         if (oldData) {
            //           this.setState((prevState) => {
            //             const data = [...prevState.data];
            //             data[data.indexOf(oldData)] = newData;
            //             return { ...prevState, data };
            //           });
            //         }
            //       }, 600);
            //     }),
            //   onRowDelete: (oldData) =>
            //     new Promise((resolve) => {
            //       setTimeout(() => {
            //         resolve();
            //         this.setState((prevState) => {
            //           const data = [...prevState.data];
            //           data.splice(data.indexOf(oldData), 1);
            //           return { ...prevState, data };
            //         });
            //       }, 600);
            //     }),
            // }}
          />
        )}
        {this.state.type === 'create' && this.state.isProcessed && (
          <div
            style={{
              marginTop: '20px',
              marginRight: 'auto',
              marginBottom: '10px',
              position: 'sticky',
              bottom: '1rem',
              width: '100%',
              backgroundColor: 'white',
              padding: '0.75rem',
              boxShadow: '0px 3px 15px rgb(0 0 0 / 20%)',
              borderRadius: '5px',
            }}
          >
            <Button
              disabled={!validateItemData()}
              width='auto'
              variant='contained'
              color='secondary'
              onClick={this.itemSubmit}
            >
              Submit
            </Button>
          </div>
        )}
        <br />

        {this.state.error && (
          <Snackbar
            open={this.state.error}
            autoHideDuration={4500}
            onClose={this.handleError}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Alert
              variant='filled'
              elevation={6}
              onClose={this.handleError}
              severity='error'
            >
              <AlertTitle>{this.state.errorTitle}</AlertTitle>
              <Typography variant='body2'>{this.state.errorMessage}</Typography>
            </Alert>
          </Snackbar>
        )}
        {this.state.success && (
          <Snackbar
            open={this.state.success}
            autoHideDuration={4500}
            onClose={this.handleSuccess}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Alert
              variant='filled'
              elevation={6}
              onClose={this.handleSuccess}
              severity='success'
            >
              <AlertTitle>{this.state.successTitle}</AlertTitle>
              <Typography variant='body2'>
                {this.state.successMessage}
              </Typography>
            </Alert>
          </Snackbar>
        )}
      </>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  currentBook: selectCurrentBook,
});

export default connect(mapStateToProps)(ViewTest);
