/* eslint-disable unused-imports/no-unused-imports */
/* eslint-disable no-unused-vars */

import { Image } from '@mui/icons-material';
import {
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import Axios from 'axios';
import Ace from 'brace';
import React, { Component } from 'react';
import 'brace/mode/json';
import 'brace/theme/github';

import JSONEditorReact from './JSONEditorReact';

const token = JSON.parse(localStorage.getItem('token'));
const modes = ['tree', 'code', 'text'];

const styles = () => ({
  input: {
    display: 'none',
  },
  uploadButton: {
    background: '#005792',
    borderRadius: '2px',
    color: '#FBFAF2',
    fontFamily: 'Rubik',
    fontWeight: '400',
    textAlign: 'center',
    fontSize: 14,
    textTransform: 'none',
  },
  progress: {
    maxWidth: '100px',
    width: '80px',
    height: '80px',
  },
});

class AddAruco extends Component {
  constructor(props) {
    super(props);

    this.state = {
      arucoId: 0,
      type: '',
      jsonData: [],
      flag: false,
      mode: 'code',
      text: '',
      bookId: '',
      questions: 1,
      questionsStart: 1,
      questionsEnd: 1,
      template: '',
      pageType: '',
      used: false,
      dewarp: '',
      gridHeight: 1280,
      gridWidth: 720,
      book: [],
      author: [],
    };
  }

  componentDidMount() {
    if (this.props.location.data === undefined) {
      this.props.history.push({
        pathname: `/home/books`,
      });
    } else {
      document.title = 'Authoring Tool | Aruco';
      const { book, author } = this.props.location.data;
      this.setState({ book: book, author: author, bookId: book._id }, () =>
        this.getLastUsedAruco()
      );
    }
  }

  getLastUsedAruco = () => {
    Axios.post(
      'https://prod.paperflowapp.com/author-aruco/aruco/lastUsedAruco',
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((response) => {
        this.setState({ arucoId: response.data.arucoId[0].arucoId + 1 });
      })
      .catch(() => {});
  };

  onChangeText = (text) => {
    this.setState({ text });
  };

  onModeChange = (mode) => {
    this.setState({ mode });
  };

  createAruco = () => {};
  render() {
    const handleJson = async (e) => {
      var jsFile = e.target.files[0];

      var fileRead = new FileReader();
      fileRead.onload = (event) => {
        var content = event.target.result;
        var jsData = JSON.parse(content);
        this.setState({
          jsonData: jsData,
          flag: true,
          text: JSON.stringify(jsData, null, 2),
        });
      };
      fileRead.readAsText(jsFile);
    };

    const { classes } = this.props;

    return (
      <Container component='main' maxWidth='sm'>
        <div className='paper'>
          <Typography className='font' component='h1' variant='h5'>
            Aruco Details
          </Typography>
          <form className='form' noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant='outlined'
                  required
                  fullWidth
                  id='arucoId'
                  label='Aruco ID'
                  name='arucoId'
                  autoComplete='arucoId'
                  onChange={(e) => this.setState({ arucoId: e.target.value })}
                  value={this.state.arucoId}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant='outlined'>
                  <InputLabel htmlFor='questions'>Total Questions</InputLabel>
                  <OutlinedInput
                    id='questions'
                    value={this.state.questions}
                    InputProps={{ inputProps: { min: 1 } }}
                    onChange={(e) =>
                      this.setState({ questions: e.target.value })
                    }
                    labelWidth={140}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  variant='outlined'
                  fullWidth
                  className='formControl'>
                  <InputLabel id='typeLabel'>Type</InputLabel>
                  <Select
                    labelId='typeLabel'
                    id='demo-simple-select-outlined'
                    value={this.state.type}
                    onChange={(e) => this.setState({ type: e.target.value })}
                    label='Type'>
                    <MenuItem value='Book Test'>Book Test</MenuItem>
                    <MenuItem value='Adaptive Test'>Adaptive Test</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete='questions_start'
                  name='questionsStart'
                  variant='outlined'
                  required
                  type='number'
                  fullWidth
                  id='questionsStart'
                  label='Questions Start'
                  autoFocus
                  InputProps={{ inputProps: { min: 1 } }}
                  value={this.state.questionsStart}
                  onChange={(e) =>
                    this.setState({ questionsStart: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete='questions_end'
                  name='questionsEnd'
                  variant='outlined'
                  required
                  type='number'
                  fullWidth
                  id='questionsEnd'
                  label='Question End'
                  InputProps={{ inputProps: { min: 1, max: 2000 } }}
                  autoFocus
                  value={this.state.questionsEnd}
                  onChange={(e) =>
                    this.setState({ questionsEnd: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoComplete='pageType'
                  name='pageType'
                  variant='outlined'
                  required
                  type='text'
                  fullWidth
                  id='pageType'
                  label='Page Type'
                  autoFocus
                  value={this.state.pageType}
                  onChange={(e) => this.setState({ pageType: e.target.value })}
                />
              </Grid>

              <Grid item>
                <div className='thumbnailUpload'>
                  <div className='uploader'>
                    <input
                      required
                      accept='.json'
                      className={classes.input}
                      id='data'
                      type='file'
                      onChange={(e) => handleJson(e)}
                    />
                    <label htmlFor='data' className='custom-file-upload'>
                      <Button
                        variant='contained'
                        className={classes.uploadButton}
                        color='primary'
                        component='span'
                        startIcon={<Image />}>
                        Select File
                      </Button>
                    </label>
                  </div>
                </div>
              </Grid>
              {/* <Grid item xs={12}>
                    
                    <Typography variant="button" gutterBottom>Upload JSON File: </Typography>
                    <input type="file" placeholder="Upload JSON File" id="data" name="data" accept=".json" onChange={e => handleJson(e)} />
                </Grid> */}
            </Grid>
          </form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {this.state.flag ? (
                <div className='content'>
                  <JSONEditorReact
                    text={this.state.text}
                    onChange={this.handleChange}
                    mode={this.state.mode}
                    modes={modes}
                    indentation={4}
                    onChangeText={this.onChangeText}
                    onModeChange={this.onModeChange}
                    theme='ace/theme/github'
                  />
                </div>
              ) : (
                <div></div>
              )}
            </Grid>
            <Grid item>
              <Button
                type='submit'
                size='medium'
                variant='contained'
                color='primary'
                className='submit'
                style={{ marginTop: '16px' }}
                onClick={() => this.createAruco()}>
                Save
              </Button>
            </Grid>
          </Grid>
        </div>
      </Container>
    );
  }
}

export default withStyles(styles, { withTheme: true })(AddAruco);
