import {
  Alert,
  Button,
  FormControl,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  Snackbar,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import Axios from 'axios';
import clsx from 'clsx';
import React, { Component } from 'react';

import CustomLoader from '../../elements/CustomLoader';

const styles = (theme) => ({
  root: {
    color: 'pink',
  },
  button: {
    fontSize: 14,
    textTransform: 'none',
    '&:disabled': {
      backgroundColor: 'grey',
    },
    '&:hover': {},
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '25ch',
  },

  outlinedInput: {
    '&:hover .MuiInputAdornment-root .MuiSvgIcon-root': {
      color: theme.palette.text.primary,
    },
    '.MuiInputAdornment-root .MuiInputAdornment-filled .MuiInputAdornment-positionStart':
      {
        color: 'black',
      },
  },
});
class Signup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mobile: '',
      loading: false,
      onError: false,
      regexp: /^[0-9\b]+$/,
    };
  }

  login = (e) => {
    e.preventDefault();
    this.props.history.push({
      pathname: '/login',
    });
  };

  handleChange = (e) => {
    if (e.target.value === '' || this.state.regexp.test(e.target.value)) {
      this.setState({ [e.target.name]: e.target.value });
    }
    /* if (!Number(e.target.value)) {
            return;
        }
        this.setState({ [e.target.name]: e.target.value }) */
  };

  render() {
    const handleSubmit = async (e) => {
      e.preventDefault();
      const phone = '+91'.concat(this.state.mobile);
      this.setState({ ...this.state, loading: true });

      Axios.post('https://prod.paperflowapp.com/author-auth/author/getOtp', {
        mobile: phone,
        type: 'SIGNUP',
      })
        .then(() => {
          this.setState({ ...this.state, isLoading: false });
          this.props.history.push({
            pathname: '/verifyOtp',
            data: {
              mobile: phone,
              type: 'SIGNUP',
            },
          });
        })
        .catch(() => {
          this.setState({ ...this.state, loading: false, onError: true });
        });
    };

    const validateForm = () => {
      return this.state.mobile.length > 9;
    };
    const handleError = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }

      this.setState({ ...this.state, onError: false });
    };

    const { classes } = this.props;

    return (
      <div className='signup'>
        <div className='signup-container'>
          <h2>Signup with OTP</h2>
          <form className='signup-form'>
            <div>
              <FormControl
                fullWidth
                className={clsx(classes.textField, classes.margin)}
                variant='outlined'>
                <OutlinedInput
                  id='outlined-adornment-mobile'
                  value={this.state.mobile}
                  onChange={this.handleChange}
                  startAdornment={
                    <InputAdornment
                      id='prefix'
                      style={{ color: 'white' }}
                      position='start'>
                      +91
                    </InputAdornment>
                  }
                  aria-describedby='outlined-mobile-helper-text'
                  inputProps={{
                    'aria-label': 'mobile',
                    color: '#FFFFFF',
                  }}
                  name='mobile'
                  className={classes.outlinedInput}
                  fullWidth
                  labelWidth={0}
                />
                <FormHelperText id='outlined-mobile-helper-text'>
                  Mobile Number
                </FormHelperText>
              </FormControl>
            </div>
          </form>
          <Button
            disabled={!validateForm()}
            variant='contained'
            color='default'
            className={classes.button}
            onClick={handleSubmit}>
            Submit
          </Button>
          <br />
          <Button
            href='#text-buttons'
            onClick={(e) => this.login(e)}
            color='primary'>
            Already have an account!
          </Button>
        </div>

        {this.state.loading && (
          <div className='loading-overlay'>
            <CustomLoader />
          </div>
        )}
        {this.state.error && (
          <Snackbar
            open={this.state.error}
            autoHideDuration={3000}
            onClose={handleError}>
            <Alert
              variant='filled'
              elevation={6}
              onClose={handleError}
              severity='error'>
              Incorrect Mobile Number! Please Try again!
            </Alert>
          </Snackbar>
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Signup);
