import BookActionTypes from './bookTypes';

export const addBook = (book) => ({
  type: BookActionTypes.ADD_BOOK,
  payload: book,
});

export const removeBook = () => ({
  type: BookActionTypes.REMOVE_BOOK,
});
