import BookActionTypes from './bookTypes';

const INITIAL_STATE = {
  book: null,
};

const bookReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BookActionTypes.ADD_BOOK:
      return {
        ...state,
        book: action.payload,
      };
    case BookActionTypes.REMOVE_BOOK:
      return {
        book: null,
      };
    default:
      return state;
  }
};

export default bookReducer;
