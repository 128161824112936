/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { LockOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { logout } from "../../../redux/user/userAction";

const PasswordResetSuccess = () => (
  <Box
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      padding: "40px 0",
      height: "100vh",
    }}
  >
    <Paper
      elevation={3}
      style={{
        maxWidth: "400px",
        width: "340px",
        // margin: "100px auto auto auto",
        padding: "60px 40px",
        background: "white",
        borderRadius: "4px",
        boxShadow: "0 2px 3px #C8D0D8",
        display: "inline-block",
        margin: "20px auto",
      }}
    >
      <div
        style={{
          borderRadius: "200px",
          height: "200px",
          width: "200px",
          background: "#F8FAF5",
          margin: "0 auto",
        }}
      >
        <i
          style={{
            color: "#9ABC66",
            fontSize: "100px",
            lineHeight: "200px",
            marginLeft: "-15px",
          }}
        >
          ✓
        </i>
      </div>
      <h1>Success</h1>
      <p>
        Your password reset link has been sent to your registered email address.
        {/* <br /> Please try logging in! */}
      </p>
      <Grid container spacing={2} style={{ marginTop: "16px" }}>
        <Grid item xs={6}>
          <Button
            href="/login"
            target="_self"
            rel="noreferrer noopener"
            color="primary"
            variant="contained"
          >
            GO TO LOGIN
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            href="https://mail.google.com/mail/"
            target="_blank"
            rel="noreferrer noopener"
            color="primary"
            variant="outlined"
          >
            OPEN MAIL
          </Button>
        </Grid>
      </Grid>
    </Paper>
  </Box>
);
const PasswordResetError = ({ message }) => (
  <Box
    component="footer"
    sx={{
      padding: "0px",
      // mt: "100px",
      margin: "auto",
      color: "#f00",
    }}
  >
    <Container maxWidth="sm">
      <Typography variant="body1">{message}</Typography>
    </Container>
  </Box>
);

function ForgotPassword() {
  const [inputs, setEmail] = useState({
    email: "",
  });
  const [state, setState] = useState({
    default: true,
    resetSuccess: false,
    resetError: false,
    errorMessage: "",
  });
  const dispatch = useDispatch();
  // reset login status
  useEffect(() => {
    dispatch(logout());
  }, []);

  function handleChange(e) {
    const { name, value } = e.target;
    setEmail((inputs) => ({ ...inputs, [name]: value }));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputs.email) {
      // dispatch(forgot(inputs));
      // 'https://dev.smartpaperapp.com/v1/auth/generateResetLink'

      await axios
        .post(
          `${process.env.REACT_APP_AUTH_API_URL}/auth/password/generateResetLink`,
          {
            email: inputs.email,
          }
        )
        .then((res) => {
          setState((prevState) => ({
            ...prevState,
            default: false,
            resetSuccess: true,
            resetError: false,
          }));
        })
        .catch((err) => {
          setState((prevState) => ({
            ...prevState,
            default: true,
            resetError: true,
            errorMessage: err.response.data.message,
          }));
        });
    }
  };

  const validateForm = () => {
    const { email } = inputs;
    return (
      email.trim().length > 0 &&
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
    );
  };

  return (
    <div>
      {state.resetSuccess ? <PasswordResetSuccess /> : <></>}

      {state.default ? (
        <Container component="main" maxWidth="xs">
          <Box
            style={{
              marginTop: "40%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar style={{ margin: 1, bgcolor: "primary" }}>
              <LockOutlined />
            </Avatar>
            <Typography component="h1" variant="h5" gutterBottom>
              Reset Password
            </Typography>
            <Box style={{ marginTop: 8 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="body2" display="block" gutterBottom>
                    Enter the email address associated with your account and
                    we'll send you a link to reset your password.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    value={inputs.email}
                    name="email"
                    label="Email Address"
                    type="email"
                    id="email"
                    placeholder="johndoe@abc.com"
                    autoComplete="email"
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Button
                onClick={handleSubmit}
                fullWidth
                // loading={loading}
                color="primary"
                variant="contained"
                style={{ marginTop: "16px", marginBottom: "8px" }}
                disabled={!validateForm()}
              >
                Continue
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link to="/login">{"Already have an account? Sign in"}</Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      ) : (
        <></>
      )}
      {state.resetError ? (
        <PasswordResetError message={state.errorMessage} />
      ) : (
        <></>
      )}
    </div>
  );
}

export default ForgotPassword;

/* <Box
    sx={{
      bgcolor: "background.paper",
      pt: 8,
      pb: 6,
    }}
  >
    <Container maxWidth="md">
      <Typography
        component="h3"
        variant="h2"
        align="center"
        color="text.primary"
        gutterBottom
      >
        Password Reset Success
      </Typography>
      <Typography variant="h6" align="center" color="text.secondary" paragraph>
        A password reset link has been sent to your email address.
      </Typography>
    </Container>
  </Box> */
